<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3 vectorbg">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
            style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <div id="stepper1" class="bs-stepper">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#test-l-1">
                        <div class="step-trigger stepper1_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper1_circle">
                                <img src="../../../assets/img/policy/Group.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan" style="font-size: small;">Basic <br>Policy Details</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-2">
                        <div class="step-trigger" id="stepper2_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper2_circle">
                                <img src="../../../assets/img/policy/Group (1).png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan" style="font-size: small;">Family Constructs</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-3">
                        <div class="step-trigger" id="stepper3_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper3_circle">
                                <img src="../../../assets/img/policy/Group 10.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan" style="font-size: small;">Premium Rater</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-4">
                        <div class="step-trigger" id="stepper4_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper4_circle">
                                <img src="../../../assets/img/policy/Group 11.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan" style="font-size: small;">Contact <br> Details</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-5">
                        <div class="step-trigger" id="stepper5_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper5_circle">
                                <img src="../../../assets/img/policy/primary.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan"  style="font-size: x-small;"> CD Statement & <br> Nominee<br> Configuration</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-6">
                        <div class="step-trigger" id="stepper6_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper6_circle">
                                <img src="../../../assets/img/policy/Group 11.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan" style="font-size: small;">Document <br> Center</span>
                        </div>
                    </div>
                    <div class="line">
                        <img src="../../../assets/img/policy/line.png" class="wstepline" />
                    </div>
                    <div class="step" data-target="#test-l-7">
                        <div class="step-trigger" id="stepper7_div">
                            <img src="../../../assets/img/policy/policyStepper.png" class="wstep" />
                            <span class="bs-stepper-circle spandiv" id="stepper7_circle">
                                <img src="../../../assets/img/policy/Group 11.png" class="wstepimg" />
                            </span>
                            <span class="bs-stepper-label labelspan" style="font-size: small;">Product  <br>Features</span>
                        </div>
                    </div>
                </div>
                <div class="bs-stepper-content">

                    <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                        <strong>Policy Details updated Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                        <strong>Something went wrong ! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div> -->

                    <div id="test-l-1" class="content">
                        <form [formGroup]="basicInfo" ><!--style="background-image: url('../../../assets//images/vectorimage1.png');"-->

                            <h3 class="bs-title">Company Details </h3>
                            <hr>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="company_id">Client <span class="redstar">*</span></label>
                                        <ng-select formControlName="company_id" id="company_id" (change)="getclientBranchName()"
                                            [items]="companyDetails"
                                            bindLabel="name"
                                            bindValue="id"
                                            [placeholder]="'Select Client'"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.company_id.errors}"
                                            required>
                                        </ng-select>
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_id.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.company_id.errors.required">
                                        Client is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4 company_idDiv" style="display: none">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="sub_entity">Sub Entities(Optional)</label>
                                        <select class="form-select" formControlName="sub_entity" id="sub_entity">
                                            <option value="" selected>Select Sub Entities</option>
                                            <option *ngFor="let entitydetails of entityDetails" [value]="entitydetails.id">{{ entitydetails.name }}</option>
                                        </select>
                                    </div>
                                </div>                                     -->

                            </div>

                            <h3 class="bs-title mt-5">Basic Policy Details </h3>
                            <hr>
                            <div class="row">

                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_number">Policy No. <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="policy_number" id="policy_number" placeholder="Policy No." (change)="checkpolicy_number($event)"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors}"
                                        required />
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_number.errors.required">
                                        Policy No. is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 cdetails">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_name">Policy Name <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="policy_name" id="policy_name" placeholder="Policy Name"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors}"
                                        required />
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_name.errors.required">
                                        Policy Name is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="start_date">Policy Start Date <span class="redstar">*</span></label>
                                        <input type="date" class="form-control" formControlName="start_date" id="start_date" placeholder="Policy Start Date" (change)="loadexpirydate()" placeholder="dd-mm-yyyy" 
                                        [attr.min]="minDate"
                                        [attr.max]="maxDate" 
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.start_date.errors}"
                                        required />
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.start_date.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.start_date.errors.required">
                                        Policy Start Date is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="end_date">Policy End Date <span class="redstar">*</span></label>
                                        <input type="date" class="form-control" formControlName="end_date" id="end_date" placeholder="Policy Start Date" [min]="afterstartselected"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.end_date.errors}"
                                        required (keydown)="preventTyping($event)" />
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.end_date.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.end_date.errors.required">
                                        Policy End Date is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_type_id">Policy Type <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="policy_type_id" id="policy_type_id" (change)="getpolicySubtype()"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_type_id.errors}"
                                        required>
                                            <option value="" selected>Select Policy Type</option>
                                            <option *ngFor="let policytype of policyType" [value]="policytype.id">{{ policytype.name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type_id.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_type_id.errors.required">
                                        Policy Type is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="policy_sub_type_id">Policy Sub Type <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="policy_sub_type_id" id="policy_sub_type_id" (change)="checkpolicy_sub_type_id()"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.policy_sub_type_id.errors}"
                                        required>
                                            <option value="" selected>Select Policy Type</option>
                                            <option *ngFor="let policytype of policySubType" [value]="policytype.id">{{ policytype.name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_sub_type_id.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.policy_sub_type_id.errors.required">
                                        Policy Sub Type is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3 master_Group_Policy_No_Div" style="display: none">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="master_Group_Policy_No">Master Group Policy No <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="master_Group_Policy_No" id="master_Group_Policy_No">
                                            <option value="" selected>Select Master Group Policy No</option>
                                            <option *ngFor="let basepolicydropdownval of basepolicydropdown" [value]="basepolicydropdownval.id">{{ basepolicydropdownval.policy_number }}</option>
                                        </select>
                                        <!-- <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.master_Group_Policy_No.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.master_Group_Policy_No.errors.required">
                                        Master Group Policy No is required
                                        </p>
                                    </ng-container> -->
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3 parantpolicyTopupDiv" style="display: none">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="topup_parent_policy_type_id">Topup Parent Policy Type</label>
                                        <select class="form-select" formControlName="topup_parent_policy_type_id" id="topup_parent_policy_type_id">
                                            <option value="0" selected>Select Topup Parent Policy Type</option>
                                            <option *ngFor="let topupparentpolicy of parentpolicyTypeTopup" [value]="topupparentpolicy.id">{{ topupparentpolicy.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3 parantpolicyDiv" style="display: none">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="parent_policy_type_id">Parent Policy Type</label>
                                        <select class="form-select" formControlName="parent_policy_type_id" id="parent_policy_type_id">
                                            <option value="" selected>Select Parent Policy Type</option>
                                            <option *ngFor="let parentpolicytype of parentpolicyType" [value]="parentpolicytype.id">{{ parentpolicytype.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="insurer_id">Insurer <span class="redstar">*</span></label>
                                        <ng-select formControlName="insurer_id" id="insurer_id"
                                            [items]="Insurer"
                                            bindLabel="name"
                                            bindValue="insure_comp_id"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.insurer_id.errors}"
                                            required>
                                        </ng-select>
                                        <ng-container
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.insurer_id.errors">
                                            <p class="error">
                                                Insurer is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div> -->

                                <div class="col-sm-3 mt-3 checkTPA">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="tpa_id">TPA</label>
                                        <select class="form-select" formControlName="tpa_id" id="tpa_id">
                                            <option value="0" selected>Select TPA</option>
                                            <option *ngFor="let tpa of TPA" [value]="tpa.tpa_id">{{ tpa.name }}</option>
                                        </select>
                                        <!-- <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.tpa_id.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.tpa_id.errors.required">
                                        TPA is required
                                        </p>
                                    </ng-container> -->
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="employee_tab_view">Employee Tab View <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="employee_tab_view" id="employee_tab_view"
                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.employee_tab_view.errors}"
                                        required>
                                            <option value="" selected>Select Employee Tab View</option>
                                            <option *ngFor="let etv of employeeTabView" [value]="etv.id">{{ etv.name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employee_tab_view.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employee_tab_view.errors.required">
                                        Employee Tab View is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="broker_commision">Broker (%) <span class="redstar">*</span></label>
                                        <input type="type" class="form-control" formControlName="broker_commision" id="broker_commision" placeholder="Broker" (keypress)="isNumber($event)"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.broker_commision.errors}"
                                        required maxlength="5" />
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.broker_commision.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.broker_commision.errors.required">
                                        Broker is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="co_oprate_buffer">Corporate Buffer (<span>&#8377;</span>) <span class="redstar">*</span></label>
                                        <input type="type" class="form-control" formControlName="co_oprate_buffer" id="co_oprate_buffer" placeholder="Corporate Buffer" (keypress)="isNumber($event)"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.co_oprate_buffer.errors}"
                                        required />
                                        <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.co_oprate_buffer.errors">
                                        <p class="error"
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.co_oprate_buffer.errors.required">
                                        Corporate Buffer is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="broker_branch_id">Broker Branch Name</label>
                                        <select class="form-select" formControlName="broker_branch_id" id="broker_branch_id">
                                            <option value="" selected>Select Broker Branch Name</option>
                                            <option *ngFor="let brokerbranchname of brokerBranchName" [value]="brokerbranchname.id">{{ brokerbranchname.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-3 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="client_branch_id">Client Branch Name</label>
                                        <select class="form-select" formControlName="client_branch_id" id="client_branch_id">
                                            <option value="0" selected>Select Client Branch Name</option>
                                            <option *ngFor="let ClientBranchName of ClientBranchName" [value]="ClientBranchName.id">{{ ClientBranchName.branch_name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-3" style="padding: 10px;">
                                    <label class="bs-form-label">GST Applicable <span class="redstar">*</span></label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_show_gst_flag" formControlName="show_gst_flag" name="show_gst_flag" value="No" (change)="radio_yesno('show_gst_flag', 'no')" />
                                        <label for="radio-two_show_gst_flag" id="show_gst_flag_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_show_gst_flag" formControlName="show_gst_flag" name="show_gst_flag" value="Yes" (change)="radio_yesno('show_gst_flag', 'yes')" />
                                        <label for="radio-one_show_gst_flag" id="show_gst_flag_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div> -->

                                <div class="col-sm-3" style="padding: 10px;">
                                    <label class="bs-form-label">Hide Member Cover <span class="redstar">*</span></label>
                                    <!-- <div class="switch-fieldDIV"> -->
                                        <div class="switch-field wFC" style="margin: 5px 0px;">
                                            <input type="radio" id="radio-two_hide_cover" formControlName="hide_cover" name="hide_cover" value="No" (change)="radio_yesno('hide_cover', 'no')" />
                                            <label for="radio-two_hide_cover" id="hide_cover_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_hide_cover" formControlName="hide_cover" name="hide_cover" value="Yes" (change)="radio_yesno('hide_cover', 'yes')" />
                                            <label for="radio-one_hide_cover" id="hide_cover_yes" class="bs-form-label">Yes</label>
                                        </div>
                                    <!-- </div>                                         -->
                                </div>

                                <div class="col-sm-3" style="padding: 10px;">
                                    <label class="bs-form-label">Hide Member Premium <span class="redstar">*</span></label>
                                    <!-- <div class="switch-fieldDIV"> -->
                                        <div class="switch-field wFC" style="margin: 5px 0px;">
                                            <input type="radio" id="radio-two_hide_premium" formControlName="hide_premium" name="hide_premium" value="No" (change)="radio_yesno('hide_premium', 'no')" />
                                            <label for="radio-two_hide_premium" id="hide_premium_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_hide_premium" formControlName="hide_premium" name="hide_premium" value="Yes" (change)="radio_yesno('hide_premium', 'yes')" />
                                            <label for="radio-one_hide_premium" id="hide_premium_yes" class="bs-form-label">Yes</label>
                                        </div>
                                    <!-- </div>                                         -->
                                </div>

                                <div class="col-sm-3" style="padding: 10px;">
                                    <label class="bs-form-label">Show Enhance Bifurcation <span class="redstar">*</span></label>
                                    <!-- <div class="switch-fieldDIV"> -->
                                        <div class="switch-field wFC" style="margin: 5px 0px;">
                                            <input type="radio" id="radio-two_show_enhance" formControlName="show_enhance" name="show_enhance" value="No" (change)="radio_yesno('show_enhance', 'no')" />
                                            <label for="radio-two_show_enhance" id="show_enhance_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_show_enhance" formControlName="show_enhance" name="show_enhance" value="Yes" (change)="radio_yesno('show_enhance', 'yes')" />
                                            <label for="radio-one_show_enhance" id="show_enhance_yes" class="bs-form-label">Yes</label>
                                        </div>
                                    <!-- </div>                                         -->
                                </div>

                                <div class="col-sm-3" style="padding: 10px;">
                                    <label class="bs-form-label">Prorata Premium Calculation <span class="redstar">*</span></label>
                                    <!-- <div class="switch-fieldDIV"> -->
                                        <div class="switch-field wFC" style="margin: 5px 0px;">
                                            <input type="radio" id="radio-two_is_prorata_premium_allowed" formControlName="is_prorata_premium_allowed" name="is_prorata_premium_allowed" value="No" (change)="radio_yesno('is_prorata_premium_allowed', 'no')" />
                                            <label for="radio-two_is_prorata_premium_allowed" id="is_prorata_premium_allowed_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_is_prorata_premium_allowed" formControlName="is_prorata_premium_allowed" name="is_prorata_premium_allowed" value="Yes" (change)="radio_yesno('is_prorata_premium_allowed', 'yes')" />
                                            <label for="radio-one_is_prorata_premium_allowed" id="is_prorata_premium_allowed_yes" class="bs-form-label">Yes</label>
                                        </div>
                                    <!-- </div>                                         -->
                                </div>

                                

                                <div class="col-sm-3 Sum_with_Base_Policy_Div" style="padding: 10px; display:none">
                                    <label class="bs-form-label">Sum with Base Policy <span class="redstar">*</span></label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_sum_with_Base_Policy" formControlName="sum_with_Base_Policy" name="sum_with_Base_Policy" value="No" (change)="radio_yesno('sum_with_Base_Policy', 'no')" />
                                        <label for="radio-two_sum_with_Base_Policy" id="sum_with_Base_Policy_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_sum_with_Base_Policy" formControlName="sum_with_Base_Policy" name="sum_with_Base_Policy" value="Yes" (change)="radio_yesno('sum_with_Base_Policy', 'yes')" />
                                        <label for="radio-one_sum_with_Base_Policy" id="sum_with_Base_Policy_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-12 mt-3">
                                    <div class="row" style="padding: 10px; background: #F2F2F2; border:dashed 1px #ADADAD; border-width: 2px; border-radius: 6px; cursor: pointer; margin: 0px;">
                                        <div class="col-sm-3 mt-3">
                                            <div class="form-group">
                                            <label class="bs-form-label" for="insurer_id">Insurer <span class="redstar">*</span></label>
                                            <ng-select formControlName="insurer_id" id="insurer_id"
                                                [items]="Insurer"
                                                bindLabel="name"
                                                bindValue="insure_comp_id"
                                                [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.insurer_id.errors}"
                                                required>
                                            </ng-select>
                                            <ng-container
                                                *ngIf="basicInfoSubmitted && getBasicInformationFormControls.insurer_id.errors">
                                                <p class="error">
                                                    Insurer is required
                                                </p>
                                            </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="insurer_perc">Insurer(%) <span class="redstar">*</span></label>
                                                <!-- <input type="text" class="form-control" formControlName="insurer_perc" (change)="fn_onchangeinsurerpercentage()" id="insurer_perc" maxlength="3" placeholder="Insurer (%)" /> -->

                                                <input type="type" class="form-control" formControlName="insurer_perc" id="insurer_perc" placeholder="Broker" (keyup)="fn_onchangeinsurerpercentage()" (keydown)="fn_onchangeinsurerpercentage()" maxlength="5"  placeholder="Insurer (%)" ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.insurer_perc.errors}"
                                                    required />
                                                    <ng-container
                                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.insurer_perc.errors">
                                                    <p class="error"
                                                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.insurer_perc.errors.required">
                                                    Insurer Percentage is required
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <!--CO INSURER SECTION-->
                                        <div class="hr_infocheck" style="display: none">

                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" formControlName="hr_info" id="hr_info" (click)="hr_check()" style="margin-left: 0px; margin-top: 0px;" />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Should create Co-Insurer?</label>
                                            </div>

                                            <div id="showaddrow" style="display: none">
                                                <div class="col-sm-12">
                                                    <div class="newhr_row">
                                                    </div>
                                                    <div class="Insurersum bs-form-label mt-2" style="display: none; color: red;">Your Co-Insurer(%) sum is greater than 100, so cannot add more Co-Insurer!!</div>
                                                </div>

                                                <div class="col-sm-3 mt-3 adrowdiv" id="adrowdiv" style="display: none">
                                                    <label class="bs-form-label" (click)="addHrRow()" style="cursor: pointer">
                                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                        <span style="color: #1489F3;">Add New Row</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-sm-6 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="description">Policy Description (No. of characters - 2000)</label>
                                        <textarea class="form-control" formControlName="description" rows="5" id="description" placeholder="Description" maxlength="2000"></textarea>
                                    </div>
                                </div>

                                <div class="col-sm-6 mt-3">
                                    <div class="row" style="padding: 10px; background: none; border-radius: 6px; cursor: pointer; margin: 0px;">

                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" formControlName="broker_verification_needed" id="broker_verification_needed" style="margin-left: 0px; margin-top: 0px;" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Will Broker verify all Enrollment and Endorsement?</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" formControlName="employer_verification_needed" id="employer_verification_needed" style="margin-left: 0px; margin-top: 0px;" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Will Employer verify all Enrollment and Endorsement?</label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!--------------------------------------------------------------------->                           

                            <div class="row mt-4 topup_setup_div" style="display: none">

                                <div class="col-sm-12">
                                    <div class="row" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px;">

                                        <h3 class="bs-title p-0">Top up Setup</h3>
                                        <hr>

                                        <!-- <div class="form-check">
                                            <input type="checkbox" class="form-check-input" formControlName="broker_verification_needed" id="broker_verification_needed" style="margin-left: 0px; margin-top: 0px;" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Mandatory</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" formControlName="employer_verification_needed" id="employer_verification_needed" style="margin-left: 0px; margin-top: 0px;" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Optional</label>
                                        </div> -->

                                        <div class="col-sm-2">
                                            <div class="form-radio">
                                                <input type="radio" class="form-radio-input" formcontrolname="topup_setup" id="tax_status_include_topup" value="1" name="topup_setup" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked>
                                                <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                    Mandatory
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-radio">
                                                <input type="radio" class="form-radio-input" formcontrolname="topup_setup" id="tax_status_exclude_topup" value="2" name="topup_setup" style="margin-left: 0px; margin-top: 0px; cursor: pointer;">
                                                <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                    Optional
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!--------------------------------------------------------------------->
                            <h3 class="bs-title mt-5">Enrollment Details</h3>
                            <hr>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="company_id">Enrollment Window</label>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" (change)="enrollement_status()" formControlName="enrollement_status" id="enrollement_status" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Enrollment Status</label>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="enrollment_window_close_mail_effective_date">Close Mail Effective Date</label>
                                        <input type="date" class="form-control" formControlName="enrollment_window_close_mail_effective_date" id="enrollment_window_close_mail_effective_date" placeholder="Policy Start Date" />
                                    </div>
                                </div> -->
                            </div>

                            <div class="row enrollement_statusDiv" style="display: none;">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="enrollment_start_date">Enrollment Start Date <span class="redstar">*</span></label>
                                        <input type="date" class="form-control" formControlName="enrollment_start_date" id="enrollment_start_date" placeholder="Enrollment Start Date" (change)="enrollment_start_date($event)"
                                            />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="enrollment_end_date">Enrollment End Date <span class="redstar">*</span></label>
                                        <input type="date" class="form-control" formControlName="enrollment_end_date" id="enrollment_end_date" placeholder="Enrollment Start Date" [min]="min_enrollment_end_date"
                                        (keypress)="preventTyping($event)" />
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="midterm_enrollement">Enrollment Considered From (Mid Term) </label>
                                        <select class="form-select" formControlName="midterm_enrollement" id="midterm_enrollement" (change)="checkmidterm_enrollement()">
                                            <option value="" selected>Select Enrollment Considered From (Mid Term)</option>
                                            <option *ngFor="let midterm_enrollement of midterm_enrollement" [value]="midterm_enrollement.id">{{ midterm_enrollement.name }}</option>
                                        </select>
                                        <!-- <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.midterm_enrollement.errors">
                                        <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.midterm_enrollement.errors.required">
                                            Enrollment Considered From (Mid Term) is required
                                        </p>
                                        </ng-container> -->
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3 Divenrollment_allowed_days_mid_term">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="enrollment_allowed_days_mid_term">Enrollment Allowed Days (Mid Term) </label>
                                        <input type="text" class="form-control" maxlength="3" formControlName="enrollment_allowed_days_mid_term" id="enrollment_allowed_days_mid_term" placeholder="Enrollment Allowed Days (Mid Term)" (keypress)="isNumberDays($event)"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                            />
                                            <!-- <small style="font-size:11px">Max length is 2</small> -->
                                        <!-- <ng-container
                                        *ngIf="basicInfoSubmitted && getBasicInformationFormControls.enrollment_allowed_days_mid_term.errors">
                                        <p class="error"
                                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.enrollment_allowed_days_mid_term.errors.required">
                                            Enrollment Allowed Days (Mid Term) is required
                                        </p>
                                        </ng-container> -->
                                    </div>
                                </div>

                            </div>

                            <hr class="my-3">
                            <div>
                                <button (click)="next(1)" id="step1button" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            </div>
                        </form>
                    </div>

                    <div id="test-l-2" class="content">
                        <form [formGroup]="familyInfo">
                            <h3 class="bs-title">Family Construct & Relation (Age Limit)</h3>
                            <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                <strong>Family Construct created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div> -->
                            <hr>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="no_of_members_allowed">No. of Allowed Members <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="no_of_members_allowed" id="no_of_members_allowed" placeholder="No. of Allowed Members" maxlength="2"  (keypress)="isNumberDays($event)"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                            [ngClass]="{'control-red': familyInfoSubmitted && getfamilyInformationFormControls.no_of_members_allowed.errors}"
                                        required />
                                        <ng-container
                                        *ngIf="familyInfoSubmitted && getfamilyInformationFormControls.no_of_members_allowed.errors">
                                        <p class="error"
                                        *ngIf="familyInfoSubmitted && getfamilyInformationFormControls.no_of_members_allowed.errors.required">
                                        No. of Allowed Members is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-6">
                                    <label class="bs-form-label" for="no_of_members_allowed">Employee Included</label>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div class="form-radio">
                                                <input type="radio" class="form-radio-input" formControlName="employee_included" value="Yes" name="employee_included" (change)="employeeIncluded()" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                                                <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-radio">
                                                <input type="radio" class="form-radio-input" formControlName="employee_included" value="No" name="employee_included" (change)="employeeIncluded()" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                 <!-- Self DIV -->
                                 <div class="col-sm-12">
                                    <div class="row">

                                        <div class="col-sm-3 form-group mt-3">
                                            <label class="bs-form-label" for="member_type">Member type </label>
                                            <input type="text" class="form-control" formControlName="member_type" readonly id="member_type" />
                                        </div>
                                        <div class="col-sm-2 form-group mt-3">
                                            <div class="form-check" style="margin-top: 30px;">
                                                <input type="checkbox" formcontrolname="no_age" id="no_age" (click)="noAge('no_age')" class="form-check-input ng-valid ng-dirty ng-touched" style="margin-left: 0px; margin-top: 0px; cursor: pointer;">
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age Limit</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 form-group mt-3 Div_no_age">
                                            <label class="bs-form-label" for="min_age">Min Age <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="min_age" (keypress)="isNumberDays($event)" maxlength="2" ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                id="min_age" />
                                        </div>
                                        <div class="col-sm-2 form-group mt-3 Div_no_age">
                                            <label class="bs-form-label" for="max_age">Max Age <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="max_age" (keypress)="isNumberDays($event)" maxlength="2"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" (change)="checkAgeLimit($event)"
                                                id="max_age" />
                                        </div>
                                    </div>
                                </div>
                                <!--  -->

                                <!--  -->
                                <h3 class="bs-title mt-4">Allowed Relation in Policy</h3>

                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="member_typeselect">Member type </label>
                                    <select class="form-select" formControlName="member_typeselect" id="member_typeselect" (change)="memberTypeselect()">
                                        <option value="0">Select Member Type</option>
                                        <option *ngFor="let membertype of Membertype" [value]="membertype.id">{{ membertype.name }}</option>
                                    </select>
                                </div>

                                <div class="accordion mt-3" id="myAccordion">

                                    <!-- Spouse DIV -->
                                    <div class="accordion-item spouseDiv mb-3 mt-3" style="display: none">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                                <span class="numberheading"></span>Spouse Details
                                                <button class="btn btn-danger" style="position: absolute; right: 60px;" (click)="removeDIV('spouse')">Remove</button>
                                            </button>									
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody">                                                                
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="no_of_spouse">No. of Spouse</label>
                                                            <input type="text" class="form-control" formControlName="no_of_spouse" id="no_of_spouse" placeholder="No. of Spouse" (keypress)="isNumberDays($event)" maxlength="1"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                        </div>
                                                    </div>
    
                                                    <div class="col-sm-2 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="no_age_spouse" id="no_age_spouse" (click)="noAge('no_age_spouse')" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age Limit</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_spouse">
                                                        <label class="bs-form-label" for="min_age_spouse">Min Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="min_age_spouse" (keypress)="isNumberDays($event)" maxlength="2"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                            id="min_age_spouse" />
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_spouse">
                                                        <label class="bs-form-label" for="max_age_spouse">Max Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="max_age_spouse" (keypress)="isNumberDays($event)" maxlength="2"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" (change)="checkAgeLimitSpouse($event)"
                                                            id="max_age_spouse" />
                                                    </div>
                                                    <div class="col-sm-3 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="marraige_certificate" id="marraige_certificate" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Marraige Certificate Mandatory</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="lgbt" id="lgbt" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">LGBTQ</label>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="row mt-2" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px;">
    
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" formControlName="midterm_allowed_for_spouse" id="midterm_allowed_for_spouse" (click)="midterm_allowed_for_spouse()" style="margin-left: 0px; margin-top: 0px;" />
                                                        <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Is Midterm Allowed for Spouse?</label>
                                                    </div>
    
                                                    <div id="midterm_allowed_for_spouseDiv" style="display: none">
    
                                                        <div class="row">
    
                                                            <div class="col-sm-6 form-group">
                                                                <label class="bs-form-label" for="spouse_enrolment_allowed_days">Spouse Enrollment Allowed Days (Mid Term)</label>
                                                                <input type="text" class="form-control" maxlength="3" formControlName="spouse_enrolment_allowed_days" (keypress)="isNumberDays($event)" id="spouse_enrolment_allowed_days" maxlength="2" placeholder="Spouse Enrollment Allowed Days (Mid Term)" 
                                                                ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                                <!-- <small style="font-size:11px">Max length is 2</small> -->
                                                            </div>
    
                                                            <div class="col-sm-6 form-group">
                                                                <label class="bs-form-label" for="min_age">Spouse Enrollment Considered From (Mid Term)</label>
                                                                <select class="form-select" formControlName="spouse_enrolment_considered" id="spouse_enrolment_considered">
                                                                    <option value="" selected>Select Spouse Enrollment</option>
                                                                    <option *ngFor="let spousemidterm of Spousemidterm" [value]="spousemidterm.id">{{ spousemidterm.name }}</option>
                                                                </select>
                                                            </div>
    
                                                        </div>
                                                    </div>
    
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                
                                    <!--  -->

                                    <!-- Children DIV -->
                                    <div class="accordion-item ChildrenDiv mb-3" style="display: none">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                                <span class="numberheading"></span>Children Details
                                                <button class="btn btn-danger" style="position: absolute; right: 60px;" (click)="removeDIV('Children')">Remove</button>
                                            </button>                                            
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody">
                                                <div class="row">
                                    
                                                    <div class="col-sm-3 form-group">
                                                        <label class="bs-form-label" for="Child_age_depend">Child Age Depends On </label>
                                                        <select class="form-select" formControlName="Child_age_depend" id="Child_age_depend">
                                                            <option value="" selected>Select Child Age Depends On</option>
                                                            <option value="1">Self</option>
                                                        </select>
                                                    </div>
                                    
                                                    <div class="col-sm-2 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="no_age_Children"
                                                                id="no_age_Children" (click)="noAge('no_age_Children')"
                                                                style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age
                                                                Limit</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_Children">
                                                        <label class="bs-form-label" for="min_age_Child">Min Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="min_age_Child"
                                                            (keypress)="isNumberDays($event)" maxlength="2" id="min_age_Child" />
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_Children">
                                                        <label class="bs-form-label" for="max_age_Child">Max Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="max_age_Child"
                                                            (keypress)="isNumberDays($event)" (change)="checkAgeLimitChild($event)" maxlength="2" id="max_age_Child"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                    </div>
                                                    <div class="col-sm-3 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="birth_certificate"
                                                                id="birth_certificate" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Birth
                                                                Certificate</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3 form-group mt-3">
                                                        <label class="bs-form-label" for="age_difference_Child">Age Difference</label>
                                                        <input type="text" class="form-control" formControlName="age_difference_Child"
                                                            (keypress)="isNumberDays($event)" maxlength="2" id="age_difference_Child"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                    </div>
                                                    <div class="col-sm-3 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="no_of_Child">No. of Child</label>
                                                            <input type="text" class="form-control" formControlName="no_of_Child" id="no_of_Child" maxlength="2" (keypress)="isNumberDays($event)"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                                placeholder="No. of Children" />
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                                <div class="row mt-3"
                                                    style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px;">
                                    
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" formControlName="midterm_allowed_for_Child"
                                                            id="midterm_allowed_for_Child"  (click)="midterm_allowed_for_child()" style="margin-left: 0px; margin-top: 0px;" />
                                                        <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Is Midterm Allowed
                                                            for Child?</label>
                                                    </div>

                                                    <div id="midterm_allowed_for_childDiv" style="display: none">
    
                                                        <div class="row">
    
                                                            <div class="col-sm-6 form-group">
                                                                <label class="bs-form-label" for="child_enrolment_allowed_days">Child Enrollment Allowed Days (Mid Term)</label>
                                                                <input type="text" class="form-control" formControlName="child_enrolment_allowed_days" maxlength="3" (keypress)="isNumberDays($event)" id="child_enrolment_allowed_days" placeholder="Child Enrollment Allowed Days (Mid Term)" ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                                <!-- <small style="font-size:11px">Max length is 2</small> -->
                                                            </div>
    
                                                            <div class="col-sm-6 form-group">
                                                                <label class="bs-form-label" for="child_enrolment_considered">Child Enrollment Considered From (Mid Term)</label>
                                                                <select class="form-select" formControlName="child_enrolment_considered" id="child_enrolment_considered">
                                                                    <option value="" selected>Select Child Enrollment</option>
                                                                    <option *ngFor="let childmidterm of Childmidterm" [value]="childmidterm.id">{{ childmidterm.name }}</option>
                                                                </select>
                                                            </div>
    
                                                        </div>
                                                    </div>
                                    
                                                </div>
                                    
                                                <h4 class="mt-3">Additional Details</h4>
                                                <div class="row mt-2"
                                                    style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px;">
                                    
                                                    <div class="col-sm-3 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="special_Child"
                                                                id="special_Child" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Special
                                                                Child</label>
                                                        </div>
                                                    </div>
                                    
                                                    <div class="col-sm-3 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="unmarried_Child"
                                                                id="unmarried_Child" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Unmarried
                                                                Child</label>
                                                        </div>
                                                    </div>
                                    
                                                    <div class="col-sm-3 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="adopted_Child"
                                                                id="adopted_Child" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Adopted
                                                                Child</label>
                                                        </div>
                                                    </div>
                                    
                                                    <div class="col-sm-2 form-group">
                                                        <label class="bs-form-label" for="no_of_twin_child">No. of Twin Child</label>
                                                        <input type="text" class="form-control" formControlName="no_of_twin_child"
                                                            (keypress)="isNumberDays($event)" maxlength="1" id="no_of_twin_child"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                    </div>
                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->

                                    <!-- Parent DIV -->
                                    <div class="accordion-item ParentDiv mb-3" style="display: none">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                                                <span class="numberheading"></span>Parents Details
                                                <button class="btn btn-danger" style="position: absolute; right: 60px;" (click)="removeDIV('Parent')">Remove</button>
                                            </button>                                            
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody">
                                                <div class="row">
                                    
                                                    <div class="col-sm-3 form-group">
                                                        <label class="bs-form-label" for="Parent_age_depend">Parents Age Depend On</label>
                                                        <select class="form-select" formControlName="Parent_age_depend" id="Parent_age_depend">
                                                            <option value="" selected>Select Parents Age Depend On</option>
                                                            <option value="1">Self</option>
                                                        </select>
                                                    </div>
                                    
                                                    <div class="col-sm-2 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="no_age_Parent"
                                                                id="no_age_Parent" (click)="noAge('no_age_Parent')"
                                                                style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age
                                                                Limit</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_Parent">
                                                        <label class="bs-form-label" for="min_age_parent">Min Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="min_age_parent"
                                                            (keypress)="isNumberDays($event)" maxlength="2" id="min_age_parent"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"/>
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_Parent">
                                                        <label class="bs-form-label" for="max_age_parent">Max Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="max_age_parent"
                                                            (keypress)="isNumberDays($event)"(change)="checkAgeLimitParent($event)" maxlength="3" id="max_age_parent"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                    </div>
                                                    <div class="col-sm-3 form-group">
                                                        <label class="bs-form-label" for="age_difference_parent">Age Difference</label>
                                                        <input type="text" class="form-control" formControlName="age_difference_parent"
                                                            (keypress)="isNumberDays($event)" maxlength="2" id="age_difference_parent"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                    </div>
                                                </div>
                                    
                                                <div class="row">
                                                    <div class="col-sm-3 mt-3 no_of_parents">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="no_of_Parents">No. of Parents</label>
                                                            <input type="text" class="form-control" formControlName="no_of_Parents" id="no_of_Parents" (keypress)="isNumberDays($event)"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                            maxlength="1" placeholder="No. of Parents" />
                                                        </div>
                                                    </div>
                                    
                                                    <div class="col-sm-6 mt-3">
                                                        <label class="bs-form-label" for="no_of_members_allowed">Parents Allowed For - Employee</label>
                                                        <div class="row mt-1">
                                                            <div class="col-sm-2">
                                                                <div class="form-radio">
                                                                    <input type="radio" class="form-radio-input" formControlName="employee_allowed_Parents"
                                                                        value="0" name="employee_allowed_Parents"
                                                                        style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                                                                    <label class="form-radio-label bs-radio-label"
                                                                        style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Male</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="form-radio">
                                                                    <input type="radio" class="form-radio-input" formControlName="employee_allowed_Parents"
                                                                        value="1" name="employee_allowed_Parents"
                                                                        style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                    <label class="form-radio-label bs-radio-label"
                                                                        style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Female</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2">
                                                                <div class="form-radio">
                                                                    <input type="radio" class="form-radio-input" formControlName="employee_allowed_Parents"
                                                                        value="2" name="employee_allowed_Parents"
                                                                        syle="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                    <label class="form-radio-label bs-radio-label"
                                                                        style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Both</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->

                                    <!-- Parents_In_Law DIV -->
                                    <div class="accordion-item Parents_In_LawDiv mb-3" style="display: none">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                                                <span class="numberheading"></span>Parent In Laws Details
                                                <button class="btn btn-danger" style="position: absolute; right: 60px;" (click)="removeDIV('Parents_In_Law')">Remove</button>
                                            </button>                                            
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody">
                                                <div class="row">

                                                    <div class="col-sm-3 form-group">
                                                        <label class="bs-form-label" for="Parents_In_Law_age_depend">Parent In Laws Age Depend On</label>
                                                        <select class="form-select" formControlName="Parents_In_Law_age_depend" id="Parents_In_Law_age_depend">
                                                            <option value="" selected>Select Parent In Laws Age Depend On</option>
                                                            <option value="2">Spouse/ Partner</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-sm-2 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="no_age_Parents_In_Law" id="no_age_Parents_In_Law" (click)="noAge('no_age_Parents_In_Law')" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age Limit</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_Parents_In_Law">
                                                        <label class="bs-form-label" for="min_age_parent_in_law">Min Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="min_age_parent_in_law" (keypress)="isNumberDays($event)" maxlength="2"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                            id="min_age_parent_in_law" />
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_Parents_In_Law">
                                                        <label class="bs-form-label" for="max_age_parent_in_law">Max Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="max_age_parent_in_law" (keypress)="isNumberDays($event)" maxlength="3" (change)="checkAgeLimitParentInLaws($event)"
                                                            id="max_age_parent_in_law" />
                                                    </div>
                                                    <div class="col-sm-3 form-group">
                                                        <label class="bs-form-label" for="age_difference_parent_in_law">Age Difference</label>
                                                        <input type="text" class="form-control" formControlName="age_difference_parent_in_law" (keypress)="isNumberDays($event)" maxlength="2"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                            id="age_difference_parent_in_law" />
                                                    </div>
                                                    <div class="col-sm-3 mt-3 no_of_parent_in_law">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="no_of_Parents_In_Laws">No. of Parent In Laws</label>
                                                            <input type="text" class="form-control" formControlName="no_of_Parents_In_Laws" id="no_of_Parents_In_Laws" maxlength="1" placeholder="No. of Parent In Laws" (keypress)="isNumberDays($event)"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <label class="bs-form-label" for="no_of_members_allowed">Parent In Law Allowed For - Employee</label>
                                                        <div class="row mt-1">
                                                            <div class="col-sm-2">
                                                                <div class="form-radio">
                                                                    <input type="radio" class="form-radio-input" formControlName="employee_allowed_Parents_In_Law" value="0" id="employee_allowed_Parents_In_Law_1" name="employee_allowed_Parents_In_Law" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                                                                    <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Male</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="form-radio">
                                                                    <input type="radio" class="form-radio-input" formControlName="employee_allowed_Parents_In_Law" value="1" id="employee_allowed_Parents_In_Law_2" name="employee_allowed_Parents_In_Law" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                    <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Female</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2">
                                                                <div class="form-radio">
                                                                    <input type="radio" class="form-radio-input" formControlName="employee_allowed_Parents_In_Law" value="2" id="employee_allowed_Parents_In_Law_3" name="employee_allowed_Parents_In_Law" syle="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                    <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Both</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!-- Parents_In_Law Parent Additional Div -->
                                                <div class="Parents_addional_div" style="display: none">
                                                    <div class="row mt-3" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px;">
                                                        <div class="col-sm-5 mt-3">
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" formControlName="parent_cross_section" id="parent_cross_section" style="margin-left: 0px; margin-top: 0px;" />
                                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Parents/ Parent in laws cross combination allowed?</label>
                                                            </div>
                                                        </div>

                                                        <!-- <div class="col-sm-3 mt-3">
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" checked formControlName="no_parent_parentInLaw" id="no_parent_parentInLaw" (click)="no_parent_parentinLaw()" style="margin-left: 0px; margin-top: 0px;" />
                                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No. of Parent or Parent in law</label>
                                                            </div>
                                                        </div> -->

                                                        <div class="col-sm-3">
                                                            <div class="form-group">
                                                                <label class="bs-form-label" for="no_of_parents_or_parentsinlaw">No. of Parents or Parent in laws </label>
                                                                <input type="text" class="form-control" formControlName="no_of_parents_or_parentsinlaw" id="no_of_parents_or_parentsinlaw" maxlength="1" placeholder="No. of Parents or Paternts in Laws" (keypress)="isNumberDays($event)" ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <!--  -->
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->

                                    <!-- Sibling DIV -->
                                    <div class="accordion-item SiblingDiv mb-3" style="display: none">
                                        <h2 class="accordion-header" id="headingFive">
                                            <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                                                <span class="numberheading"></span>Sibling Details
                                                <button class="btn btn-danger" style="position: absolute; right: 60px;" (click)="removeDIV('Sibling')">Remove</button>
                                            </button>                                            
                                        </h2>
                                        <div id="collapseFive" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                                            <div class="card-body accorCardbody">
                                                <div class="row">

                                                    <div class="col-sm-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="no_of_Siblings">No. of Siblings</label>
                                                            <input type="text" class="form-control" formControlName="no_of_Siblings" id="no_of_Siblings" maxlength="1" placeholder="No. of Siblings" (keypress)="isNumberDays($event)"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 form-group">
                                                        <div class="form-check" style="margin-top: 30px;">
                                                            <input type="checkbox" class="form-check-input" formControlName="no_age_Sibling" id="no_age_Sibling" (click)="noAge('no_age_Sibling')" style="margin-left: 0px; margin-top: 0px;" />
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age Limit</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_Sibling">
                                                        <label class="bs-form-label" for="min_age_sibling">Min Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="min_age_sibling" (keypress)="isNumberDays($event)" maxlength="2"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                            id="min_age_sibling" />
                                                    </div>
                                                    <div class="col-sm-2 form-group Div_no_age_Sibling">
                                                        <label class="bs-form-label" for="max_age_sibling">Max Age <span class="redstar">*</span></label>
                                                        <input type="text" class="form-control" formControlName="max_age_sibling" (keypress)="isNumberDays($event)" maxlength="2" (change)="checkAgeLimitSibling($event)"  ondragstart="return false;"  ondrop="return false;"  (paste)="handlePaste($event)"
                                                            id="max_age_sibling" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    
                                </div>                                
                                                                
                            </div>
                        </form>
                        <hr>
                        <div>
                            <button (click)="next(2);" id="step2button" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                        </div>
                    </div>

                    <div id="test-l-3" class="content">
                        <form [formGroup]="SIPremiumInfo">
                            <h3 class="bs-title">SI & Premium</h3>
                            <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                <strong>SI & Premium created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div> -->
                            <hr>

                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="cover_type">Cover Type <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="cover_type" id="cover_type"
                                        [ngClass]="{'control-red': SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.cover_type.errors}"
                                        required>
                                            <option value="" selected>Select Cover Type</option>
                                            <option *ngFor="let covertype of coverType" [value]="covertype.id">{{ covertype.name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.cover_type.errors">
                                        <p class="error"
                                        *ngIf="SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.cover_type.errors.required">
                                        Cover Type is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="basic_policy_mapping">Base Policy Mapping </label>
                                        <select class="form-select" formControlName="basic_policy_mapping" id="basic_policy_mapping">
                                            <option value="" selected>Select Base Policy Mapping </option>
                                        </select>
                                    </div>
                                </div> -->

                            </div>

                            <div class="row mt-3">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="SI_Type">Sum Insured Type <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="SI_Type" id="SI_Type"
                                        [ngClass]="{'control-red': SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.SI_Type.errors}"
                                        required>
                                            <option value="" selected>Select Sum Insured Type</option>
                                            <option *ngFor="let sIType of SIType" [value]="sIType.id">{{ sIType.name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.SI_Type.errors">
                                        <p class="error"
                                        *ngIf="SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.SI_Type.errors.required">
                                        Sum Insured Type is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="SI_Basis">Sum Insured Basis <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="SI_Basis" id="SI_Basis" (change)="Sum_Insured_BasisDiv()"
                                        [ngClass]="{'control-red': SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.SI_Basis.errors}"
                                        required>
                                            <option value="" selected>Select Sum Insured Basis</option>
                                            <option *ngFor="let sIBasis of SIBasis" [value]="sIBasis.id">{{ sIBasis.name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.SI_Basis.errors">
                                        <p class="error"
                                        *ngIf="SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.SI_Basis.errors.required">
                                        Sum Insured Basis is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="premium_Basis">Premium Basis <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="premium_Basis" id="premium_Basis" (change)="showpremiumBasis()"
                                        [ngClass]="{'control-red': SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.premium_Basis.errors}"
                                        required>
                                            <option value="" selected>Select Premium Basis</option>
                                            <option *ngFor="let premiumbasis of premiumBasis" [value]="premiumbasis.id">{{ premiumbasis.name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.premium_Basis.errors">
                                        <p class="error"
                                        *ngIf="SIPremiumInfoSubmitted && getSIPremiumInformationFormControls.premium_Basis.errors.required">
                                        Premium Basis is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                            </div>

                            <!-- Employee Age -->
                            <div class="mt-4 employee_age_div" style="display: none">
                                
                                <div class="row" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px; ">
                                    <div class="col-sm-12 mb-2"><label class="bs-radio-label" style="font-size: 16px"><b>Premium Tax Included/Excluded ?</b></label></div>                                                                               
                                    <div class="col-sm-2">
                                        <div class="form-radio">
                                            <input type="radio" class="form-radio-input" formcontrolname="tax_status" id="tax_status_include" value="included" name="tax_status" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (change)="checktax_employee(1)" checked>
                                            <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                Tax Included
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-radio">
                                            <input type="radio" class="form-radio-input" formcontrolname="tax_status" id="tax_status_exclude" value="excluded" name="tax_status" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (change)="checktax_employee(0)">
                                            <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                Tax Excluded
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 employee_tax_age_div"></div>

                                    <div class="col-sm-3 employee_tax_age_div mt-2"> 
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employee_premium_tax">Premium Tax <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="employee_premium_tax" id="employee_premium_tax" placeholder="Premium Tax" maxlength="3" (keypress)="isNumber($event)" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    
                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="emplyee_age_file" *ngIf="emmployeefile_id == 2">Employee Age Premium File <span class="redstar">*</span></label>
                                        <label class="bs-form-label" for="emplyee_age_file"  *ngIf="emmployeefile_id == 8">Member Age Premium File <span class="redstar">*</span></label>
                                        <label class="bs-form-label" for="emplyee_age_file" *ngIf="emmployeefile_id == 9">Member Wise Premium File <span class="redstar">*</span></label>
                                        <label class="bs-form-label" for="emplyee_age_file" *ngIf="emmployeefile_id == 13">Family Construct Wise Premium File <span class="redstar">*</span></label>
                                        <label class="bs-form-label" for="emplyee_age_file" *ngIf="emmployeefile_id == 15">New Grade With Construct Premium File <span class="redstar">*</span></label>
                                        <label class="bs-form-label" for="emplyee_age_file" *ngIf="emmployeefile_id == 1">Grade Sum INsured File <span class="redstar">*</span></label>
                                        <label class="bs-form-label" for="emplyee_age_file" *ngIf="emmployeefile_id == 21"> Perlife / Relation -Age Brand</label>

                                        <label for="file_field_employee_file" id="custom-file-upload_emplyee_age_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_emplyee_age_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
        
                                        <input id="file_field_employee_file" type="file" (change)="employee_file_change($event)" formControlName="emplyee_age_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           

                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_emplyee_age_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_employee()">Download Sample File</small>
                                    </div>                                        
                                    <small class="error" style="display: none; font-weight:normal" id="premiumfile_error">Please upload the Premium file</small>           
                                    
                                    <div class="col-sm-6"></div>
                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFiles()" style="font-size: 14px; color:#2a57e9;">Download File </small>
                                         </div>
                                    </div>


                                </div>                                    
                                
                            </div>

                            <!-- Grade Div -->
                            <div class="grade_div mt-3" style="display: none">

                                <div class="row" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px; ">
                                    <div class="col-sm-12 mb-2"><label class="bs-radio-label" style="font-size: 16px"><b>Premium Tax Included/Excluded ?</b></label></div>                                                                               
                                    <div class="col-sm-2">
                                        <div class="form-radio">
                                            <input type="radio" class="form-radio-input" formcontrolname="tax_status3" id="tax_status3_include" value="included" name="tax_status3" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (change)="checktax_employee(1)" checked>
                                            <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                Tax Included
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-radio">
                                            <input type="radio" class="form-radio-input" formcontrolname="tax_status3" id="tax_status3_exclude" value="excluded" name="tax_status3" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (change)="checktax_employee(0)">
                                            <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                Tax Excluded
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12"></div>

                                    <div class="col-sm-3 employee_tax_age_div mt-2"> 
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employee_premium_tax3">Premium Tax <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="employee_premium_tax3" id="employee_premium_tax3" placeholder="Premium Tax" maxlength="3" (keypress)="isNumber($event)" />
                                        </div>
                                    </div>

                                    <div class="col-sm-12"></div>

                                    <div class="col-sm-6 form-group mt-4">
                                        <label class="bs-form-label" for="grade_SI_file">Grade Sum Insured File <span class="redstar">*</span></label>                                            
                                    
                                        <label for="file_field_grade_SI_file" id="custom-file-upload_grade_SI_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px; background: #fff;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_grade_SI_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                    
                                        <input id="file_field_grade_SI_file" type="file" (change)="grade_SI_file_change($event)" formControlName="grade_SI_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           
                                    
                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_grade_SI_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_grade_SI()">Download Sample File</small>
                                        <small class="error" style="display: none; font-weight:normal" id="gradefile_error">Please upload the Grade Sum Insured file</small>
                                    </div>

                                    <div class="col-sm-6 form-group mt-4">
                                        <label class="bs-form-label" for="grade_SP_file" *ngIf="emmployeefile_id == 4">Grade Premium File <span class="redstar">*</span></label>
                                        <label class="bs-form-label" for="grade_SP_file" *ngIf="emmployeefile_id == 2">Employee Age Premium File <span class="redstar">*</span></label>                                            
                                        <label class="bs-form-label" for="grade_SP_file" *ngIf="emmployeefile_id == 15">Grade Premium Rater New Construct <span class="redstar">*</span></label>                                            
                                    
                                        <label for="file_field_grade_SP_file" id="custom-file-upload_grade_SP_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px; background: #fff;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_grade_SP_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                    
                                        <input id="file_field_grade_SP_file" type="file" (change)="grade_SP_file_change($event)" formControlName="grade_SP_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           
                                    
                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_grade_SP_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_employee()">Download Sample File</small>
                                        <small class="error" style="display: none; font-weight:normal" id="gradefile_SP_error">Please upload the Grade Premium file</small>
                                    </div>

                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFiles()" style="font-size: 14px; color:#2a57e9;">Download Grade File </small>
                                         </div>
                                    </div>

                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFilesPremium()" style="font-size: 14px; color:#2a57e9;">Download Grade Premium File </small>
                                         </div>
                                    </div>

                                </div>                                                                    

                            </div>

                             <!-- Family Construct Wise  Div -->
                             <div class="row family_construct_div"  style="display: none">
                                
                                <div class="row mt-3" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px; ">
                                    
                                    <div class="col-sm-6 form-group mt-4">
                                        <label class="bs-form-label" for="family_SI_file">Sum Insured File <span class="redstar">*</span></label>                                            
                                    
                                        <label for="file_field_family_SI_file" id="custom-file-upload_family_SI_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px; background: #fff;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_family_SI_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                    
                                        <input id="file_field_family_SI_file" type="file" (change)="family_SI_file_change($event)" formControlName="family_SI_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           
                                    
                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_family_SI_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_family_SI()">Download Sample File</small>
                                        <small class="error" style="display: none; font-weight:normal" id="family_constructSI_file_error">Please upload the Family Construct Sum Insured file</small>
                                    </div>


                                    <div class="col-sm-6 form-group mt-4">
                                        <label class="bs-form-label" for="family_SP_file">Premium File <span class="redstar">*</span></label>                                    
                                        <label for="file_field_family_SP_file" id="custom-file-upload_family_SP_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px; background: #fff;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_grade_SP_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                    
                                        <input id="file_field_family_SP_file" type="file" (change)="family_SP_file_change($event)" formControlName="family_SP_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           
                                    
                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_family_SP_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_family_SP()">Download Sample File</small>
                                        <small class="error" style="display: none; font-weight:normal" id="family_constructSP_file_error">Please upload the Family Construct Premium file</small>
                                    </div>

                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFiles()" style="font-size: 14px; color:#2a57e9;">Download File </small>
                                         </div>
                                    </div>

                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFilesPremium()" style="font-size: 14px; color:#2a57e9;">Download Premium File </small>
                                         </div>
                                    </div>

                                </div>                                                                    

                             </div>

                             <!-- Family Group Wise  Div -->
                             <div class="row family_group_div"  style="display: none">
                                
                                <div class="row mt-3" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px; ">
                                    
                                    <div class="col-sm-6 form-group mt-4">
                                        <label class="bs-form-label" for="family_group_SI_file">Sum Insured File <span class="redstar">*</span></label>                                            
                                    
                                        <label for="file_field_family_group_SI_file" id="custom-file-upload_family_group_SI_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px; background: #fff;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_family_group_SI_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                    
                                        <input id="file_field_family_group_SI_file" type="file" (change)="family_Group_SI_file_change($event)" formControlName="family_group_SI_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           
                                    
                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_family_group_SI_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_family_Group_SI()">Download Sample File</small>
                                        <small class="error" style="display: none; font-weight:normal" id="family_Group_SIfile_error">Please upload the Grade Sum Insured file</small>
                                    </div>

                                    <div class="col-sm-6 form-group mt-4">
                                        <label class="bs-form-label" for="family_group_SP_file">Premium File <span class="redstar">*</span></label>                                    
                                        <label for="file_field_family_group_SP_file" id="custom-file-upload_family_group_SP_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px; background: #fff;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_family_group_SP_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                    
                                        <input id="file_field_family_group_SP_file" type="file" (change)="family_Group_SP_file_change($event)" formControlName="family_group_SP_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           
                                    
                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_family_group_SP_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_family_Group_SP()">Download Sample File</small>
                                        <small class="error" style="display: none; font-weight:normal" id="family_Group_SPfile_error">Please upload the Grade Premium file</small>
                                    </div>

                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFiles()" style="font-size: 14px; color:#2a57e9;">Download File </small>
                                         </div>
                                    </div>

                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFilesPremium()" style="font-size: 14px; color:#2a57e9;">Download Premium File </small>
                                         </div>
                                    </div>

                                </div>                                                                    

                             </div>

                            <!-- Number Of Times Salary Div -->
                            <div class="no_times_salary_div mt-3" style="display: none">

                                <div class="row" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px; ">
                                    <div class="col-sm-12 mb-2"><label class="bs-radio-label" style="font-size: 16px"><b>Premium Tax Included/Excluded ?</b></label></div>                                                                               
                                    <div class="col-sm-2">
                                        <div class="form-radio">
                                            <input type="radio" class="form-radio-input" formcontrolname="tax_status2" id="tax_status2_include" value="included" name="tax_status2" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (change)="checktax_employee(1)" checked>
                                            <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                Tax Included
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-radio">
                                            <input type="radio" class="form-radio-input" formcontrolname="tax_status2" id="tax_status2_exclude" value="excluded" name="tax_status2" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (change)="checktax_employee(0)">
                                            <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                Tax Excluded
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 employee_tax_age_div"></div>

                                    <div class="col-sm-3 employee_tax_age_div mt-2"> 
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employee_premium_tax2">Premium Tax <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="employee_premium_tax2" id="employee_premium_tax2" placeholder="Premium Tax" maxlength="3" (keypress)="isNumber($event)" />
                                        </div>
                                    </div>

                                    <div class="col-sm-12 employee_tax_age_div">

                                        <!-- No of Times Div -->
                                        <div class="row">
                                            <div class="col-sm-3 form-group mt-3">
                                                <label class="bs-form-label">No of Times of Salary</label>
                                                <input type="text" class="form-control" formControlName="no_of_times_salary" id="no_of_times_salary" (keypress)="isNumber($event)" placeholder="No of Times of Salary" />
                                            </div>
                                            
                                            <div class="col-sm-3 form-group mt-3">
                                                <label class="bs-form-label">Lowest SI Limit</label>
                                                <input type="text" class="form-control" formControlName="lowest_SI_limit" id="lowest_SI_limit" (keypress)="isNumber($event)" placeholder="Lowest SI Limit" />
                                            </div>
                                            
                                            <div class="col-sm-3 form-group mt-3">
                                                <label class="bs-form-label">Highest SI Limit</label>
                                                <input type="text" class="form-control" formControlName="highest_SI_limit" id="highest_SI_limit" (keypress)="isNumber($event)" placeholder="Highest SI Limit" />
                                            </div>                                                

                                            <div class="col-sm-2 form-group mt-3">
                                                <button class="btn btn-primary" (click)="no_of_times_salary()" style="margin-top: 23px;"> + Add</button>
                                            </div>
                                            
                                            <div class="col-sm-1"></div>
                                            
                                            <!-- No of Times of Salary -->
                                            <div class="no_of_times_salary mt-3" style="display: none">
                                                <div class="mt-2" style="padding: 10px; background: #F2F2F2; border-radius: 6px;">
                                                    <span *ngFor="let nosalary of NoOfTimesSalary" style="margin-left: 20px; background: white;padding: 1px 5px 8px 5px;border-radius: 4px;">
                                                        <label class="bs-form-label">{{nosalary['no_of_times_salary']}} : {{nosalary['lowest_SI_limit']}} (Min) : {{nosalary['highest_SI_limit']}} (Max)</label>
                                                        <img src="../../../assets/img/common icons/trash.png" (click)="popNoOfTimesSalary(nosalary['no_of_times_salary'])" role="button" class="img-fluid mt-3 pt-2" style="width: 15px;margin-left: 10px;position: relative;top: -12px;" title="Remove" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- Salary Calculate From -->
                                    <div class="col-sm-6 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="salary_type">Salary Calculate From <span class="redstar">*</span></label>
                                            <select class="form-select" formControlName="salary_type" id="salary_type">
                                                <option value="" selected>Select Salary Type</option>
                                                <option value="1">Salary Type 1</option>
                                                <option value="2">Salary Type 2</option>
                                                <option value="3">Salary Type 3</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- Permily Age Wise Premium File -->
                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="permily_age_file">Permily Age Wise Premium File <span class="redstar">*</span></label>                                            
                                    
                                        <label for="file_field_permily_age_file" id="custom-file-upload_permily_age_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px; background: #fff;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_permily_age_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                    
                                        <input id="file_field_permily_age_file" type="file" (change)="permily_age_file_change($event)" formControlName="permily_age_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           
                                    
                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_permily_age_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_employee()">Download Sample File</small>
                                        <small class="error" style="display: none; font-weight:normal" id="permilyfile_error">Please upload the Permily Age Wise Premium File</small>
                                    </div>      

                                    <div class="col-sm-6"></div>
                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFiles()" style="font-size: 14px; color:#2a57e9;">Download File </small>
                                         </div>
                                    </div>

                                    
                                </div>                                                                    

                            </div>                                

                            <!-- Generate Sum Insured -->
                            <!-- <div class="col-sm-12 mt-4 Sum_Insured_BasisDiv" style="display: none;">
                                <h3 class="bs-title">Generate Sum Insured</h3>
                                <div class="row" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px;">

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="minimum_SI">Minimum SI</label>
                                            <input type="text" class="form-control" formControlName="minimum_SI" id="minimum_SI" placeholder="Minimum SI" (keypress)="isNumber($event)" />
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="maximum_SI">Maximum SI</label>
                                            <input type="text" class="form-control" formControlName="maximum_SI" id="maximum_SI" placeholder="Minimum SI" (keypress)="isNumber($event)" />
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="interval_between_min_max_SI">Interval Between Min & Max SI</label>
                                            <input type="text" class="form-control" formControlName="interval_between_min_max_SI" id="interval_between_min_max_SI" placeholder="Minimum SI" (keypress)="isNumber($event)" />
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <button class="btn btn-primary mt-4" (click)="GenerateSI()">Generate</button>
                                    </div>

                                </div>
                            </div> -->

                            <!-- Unnamed Employee Age -->
                            <div class="unnamedDiv mt-3" style="display: none">
                                <div class="row" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px; ">

                                    <div class="col-sm-12 mb-2"><label class="bs-radio-label" style="font-size: 16px"><b>Premium Tax Included/Excluded ?</b></label></div>                                                                               
                                    <div class="col-sm-2">
                                        <div class="form-radio">
                                            <input type="radio" class="form-radio-input" formcontrolname="tax_status1" id="tax_status1_include" value="included" name="tax_status1" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (change)="checktax_employee_step4(1)" checked>
                                            <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                Tax Included
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-radio">
                                            <input type="radio" class="form-radio-input" formcontrolname="tax_status1" id="tax_status1_exclude" value="excluded" name="tax_status1" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (change)="checktax_employee_step4(0)">
                                            <label class="form-radio-label bs-radio-label" style="vertical-align: text-top; margin-left: 10px; cursor: pointer;">
                                                Tax Excluded
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12"></div>

                                    <div class="col-sm-3 employee_tax_age_div_4 mt-2"> 
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employee_premium_tax1">Premium Tax <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="employee_premium_tax1" id="employee_premium_tax1" placeholder="Premium Tax" maxlength="3" (keypress)="isNumber($event)" />
                                        </div>
                                    </div>

                                    <div class="col-sm-12"></div>

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="unnamed_file">Upload File <span class="redstar">*</span></label>
                                    
                                        <label for="file_field_unnamed_file" id="custom-file-upload_unnamed_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 3px 10px; background: #fff;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_unnamed_file" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                        </label>
                                    
                                        <input id="file_field_unnamed_file" type="file" (change)="unnamed_file_change($event)" formControlName="unnamed_file" accept=".xls, .xlsx" enctype="multipart/form-data" style="display: none" />                                                           
                                    
                                        <small style="font-size: 12px; color:#605D62; position: relative;top: -7px;" id="small_unnamed_file">Upload only .xls, .xlsx file</small>
                                        <small style="font-size: 12px; color:#2a57e9; float:right; cursor: pointer" (click)="ondownload_unnamed()">Download Sample File</small>
                                        <small class="error" style="display: none; font-weight:normal" id="unnamed_error">Please upload the Unnamed file</small>
                                    </div>

                                    <div class="col-sm-12"></div>

                                    <div class="col-sm-6"></div>
                                    <div class="col-sm-6 mt-2">
                                        <div *ngIf="edit_file_url != ''">
                                            <small (click)="onDownloadFiles()" style="font-size: 14px; color:#2a57e9;">Download File </small>
                                         </div>
                                    </div>


                                    <div class="col-sm-3 form-group mt-3">
                                        <label class="bs-form-label">Total Members</label>
                                        <input type="text" class="form-control" formControlName="total_employee" id="total_employee" (keypress)="isNumber($event)" placeholder="Total Members" />
                                    </div>
                                    
                                    <div class="col-sm-3 form-group mt-3">
                                        <label class="bs-form-label">Total Sum Insured</label>
                                        <input type="text" class="form-control" formControlName="total_SI" id="total_SI" (keypress)="isNumber($event)" placeholder="Total Sum Insured" />
                                    </div>
                                    
                                    <div class="col-sm-3 form-group mt-3">
                                        <label class="bs-form-label">Total Premium</label>
                                        <input type="text" class="form-control" formControlName="total_premium" id="total_premium" (keypress)="isNumber($event)" placeholder="Total Premium" />
                                    </div>


                                </div>
                            </div>          
                            
                            <!-- Unnamed Unnamed -->
                            <div class="unnamed_unnamedDiv mt-3" style="display: none">
                                <div class="row" style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px; ">                                       

                                    <div class="col-sm-3 form-group">
                                        <label class="bs-form-label">Total Members</label>
                                        <input type="text" class="form-control" formControlName="total_employee_unnamed" id="total_employee_unnamed" (keypress)="isNumber($event)" placeholder="Total Members" />
                                    </div>

                                    <div class="col-sm-3 form-group">
                                        <label class="bs-form-label">Individual Cover</label>
                                        <input type="text" class="form-control" formControlName="gpa_individual_cover" id="gpa_individual_cover" (keypress)="isNumber($event)" placeholder="Individual Cover" />
                                    </div>
                                    
                                    <div class="col-sm-3 form-group">
                                        <label class="bs-form-label">Total Sum Insured</label>
                                        <input type="text" class="form-control" formControlName="total_SI_unnamed" id="total_SI_unnamed" (keypress)="isNumber($event)" placeholder="Total Sum Insured" />
                                    </div>
                                    
                                    <div class="col-sm-3 form-group">
                                        <label class="bs-form-label">Total Premium</label>
                                        <input type="text" class="form-control" formControlName="total_premium_unnamed" id="total_premium_unnamed" (keypress)="isNumber($event)" placeholder="Total Premium" />
                                    </div>                                    

                                    <h3 class="bs-title mt-3">Additional Cover</h3>
                                    <div class="col-sm-12"><hr style="margin-bottom: 0px;"></div>

                                    <!-- Additional Cover -->
                                    <div class="col-sm-3 form-group">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" formControlName="additional_cover" id="additional_cover" (change)="additional_cover()" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Add Benefit</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12"></div>
                                    
                                    <div class="col-sm-3 form-group additional_cover" style="display: none">
                                        <label class="bs-form-label">Add Label</label>
                                        <input type="text" class="form-control" formControlName="additional_benefit_label" id="additional_benefit_label" placeholder="Add Label" />
                                    </div>
                                    
                                    <div class="col-sm-3 form-group additional_cover" style="display: none">
                                        <label class="bs-form-label">Add Limit</label>
                                        <input type="text" class="form-control" formControlName="additional_benefit_limit" id="additional_benefit_limit" (keypress)="isNumber($event)" placeholder="Add Limit" />
                                    </div>

                                    <div class="col-sm-2 form-group additional_cover" style="display: none">
                                        <button class="btn btn-primary" (click)="additionalCover()" style="margin-top: 23px;"> + Add</button>
                                    </div>
                                    
                                    <!-- Add Benefit -->
                                    <div class="additionalCover" style="display: none">
                                        <div style="padding: 10px; background: #F2F2F2; border-radius: 6px;">                                           
                                            <span *ngFor="let additionalarray of AdditionalCoverArray" style="margin-left: 20px; background: white;padding: 1px 5px 8px 5px;border-radius: 4px;">
                                                <label class="bs-form-label">{{additionalarray['label']}} - {{additionalarray['limit']}}</label>
                                                <img src="../../../assets/img/common icons/trash.png" (click)="popadditionalCoverArray(additionalarray['label'])" role="button" class="img-fluid mt-3 pt-2" style="width: 15px;margin-left: 10px;position: relative;top: -12px;" title="Remove" />
                                            </span>
                                        </div>
                                    </div>

                                </div>                                

                            </div>                           

                            <!--Table SI -->
                            <!-- <div class="col-sm-12 mt-3 SITable" style="display: none">
                                <div style="padding: 10px; background: #F2F2F2; border-radius: 6px; cursor: pointer; margin: 0px;">

                                    <div *ngFor="let itemSI of GenerateSICount; let i = index">
                                        <div class="row" id="SIRow_{{i}}">
                                            <div class="col-sm-3 form-group mt-3">
                                                <label class="bs-form-label">Sum Insured</label>
                                                <input type="text" class="form-control" id="sum_insured_{{i}}" (keypress)="isNumber($event)" [value]="itemSI" placeholder="Sum Insured" readonly />
                                            </div>
                                            <div class="col-sm-3 form-group mt-3">
                                                <label class="bs-form-label">Premium</label>
                                                <input type="text" class="form-control" id="premium_{{i}}" (keypress)="isNumber($event)" (keyup)="sum_insured_Total(i)" (keydown)="sum_insured_Total(i)" placeholder="Premium" />
                                            </div>
                                            <div class="col-sm-3 form-group mt-3">
                                                <label class="bs-form-label">Premium Tax</label>
                                                <input type="text" class="form-control" id="premium_tax_{{i}}" (keypress)="isNumber($event)" (keyup)="sum_insured_Total(i)" (keydown)="sum_insured_Total(i)" placeholder="Premium Tax" />
                                            </div>
                                            <div class="col-sm-2 form-group mt-3">
                                                <label class="bs-form-label">Premium Total</label>
                                                <input type="text" class="form-control" id="premium_total_{{i}}" (keypress)="isNumber($event)" placeholder="Premium Total" />
                                            </div>
                                            <div class="col-sm-1 form-group mt-3 pt-2">
                                                <img src="../../../assets/img/common icons/trash.png" id="sum_insured_remove_{{itemSI}}" (click)="SIRemove('SIRow_'+i, i)" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div> -->

                            <hr>
                            <div class="col-sm-12">
                                <div class="row newCustomField">

                                </div>
                            </div>
                            <div class="col-sm-12 mt-3 Sum_Insured_BasisDiv" style="display: none">
                                <label class="bs-form-label" (click)="addSICustomField()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New </span>
                                </label>
                            </div>
                            <hr class="Sum_Insured_BasisDiv" style="display: none">

                            <!-- Tabs Headings -->
                            <ul class="nav nav-tabs mt-4" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active w100 CA-tab" id="CA-tab" data-bs-toggle="tab" data-bs-target="#CA" type="button"
                                        role="tab" aria-controls="CA" aria-selected="true">Contribution For All</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link w100 AP-tab" id="AP-tab" data-bs-toggle="tab" data-bs-target="#AP" type="button" 
                                        role="tab" aria-controls="AP" aria-selected="false" disabled>Additional Premium</button>
                                </li>
                            </ul>

                            <!-- Tabs Content -->
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="CA" role="tabpanel" aria-labelledby="CA-tab">

                                    <h3 class="bs-title">Contribution</h3>
                                    <hr>

                                    <div class="row">

                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="ca_employer">Employer% <span class="redstar">*</span></label>
                                                <input type="text" class="form-control" formControlName="ca_employer" id="ca_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                            </div>
                                        </div>

                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="ca_employee">Employee% <span class="redstar">*</span></label>
                                                <input type="text" class="form-control" formControlName="ca_employee" id="ca_employee" placeholder="Employee%" (keypress)="isNumber($event)" value="0"/>
                                            </div>
                                        </div>

                                        <div class="col-sm-3">
                                            <label class="bs-form-label">Hide Employer Contribution</label>
                                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                                    <input type="radio" id="radio-two_hide_employer_contribution" formControlName="hide_employer_contribution" name="hide_employer_contribution" value="No" (change)="radio_yesno('hide_employer_contribution', 'no')" />
                                                    <label for="radio-two_hide_employer_contribution" id="hide_employer_contribution_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_hide_employer_contribution" formControlName="hide_employer_contribution" name="hide_employer_contribution" value="Yes" (change)="radio_yesno('hide_employer_contribution', 'yes')" />
                                                    <label for="radio-one_hide_employer_contribution" id="hide_employer_contribution_yes" class="bs-form-label">Yes</label>
                                                </div>
                                        </div>

                                        <div class="col-sm-3">
                                            <label class="bs-form-label">Hide Employee Contribution</label>
                                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                                    <input type="radio" id="radio-two_hide_employee_contribution" formControlName="hide_employee_contribution" name="hide_employee_contribution" value="No" (change)="radio_yesno('hide_employee_contribution', 'no')" />
                                                    <label for="radio-two_hide_employee_contribution" id="hide_employee_contribution_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_hide_employee_contribution" formControlName="hide_employee_contribution" name="hide_employee_contribution" value="Yes" (change)="radio_yesno('hide_employee_contribution', 'yes')" />
                                                    <label for="radio-one_hide_employee_contribution" id="hide_employee_contribution_yes" class="bs-form-label">Yes</label>
                                                </div>
                                        </div>

                                    </div>

                                    <div class="row mt-5">
                                        <!-- Special Child Contribution -->
                                        <div class="col-sm-6 tabSpecialChild mb-5" style="display: none;">
                                            <h3 class="bs-title">Special Child Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="special_child_contribution_all_premium">Premium<span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="special_child_contribution_all_premium" id="special_child_contribution_all_premium" placeholder="Premium" (keypress)="isNumber($event)" />
                                                            <small class="error" style="display: none; font-weight:normal" id="special_child_contribution_all_premium_error">Premium Required</small>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="special_child_contribution_all_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="special_child_contribution_all_employer" id="special_child_contribution_all_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="special_child_contribution_all_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="special_child_contribution_all_employee" id="special_child_contribution_all_employee" placeholder="Employee%" (keypress)="isNumber($event)"/>
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                        <!-- Unmarried Child Contribution -->
                                        <div class="col-sm-6 tabUnmarriedChildren mb-5" style="display: none;">
                                            <h3 class="bs-title">Unmarried Child Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="unmarried_child_contribution_all_premium">Premium<span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="unmarried_child_contribution_all_premium" id="unmarried_child_contribution_all_premium" placeholder="Premium" (keypress)="isNumber($event)" />
                                                            <small class="error" style="display: none; font-weight:normal" id="unmarried_child_contribution_all_premium_error">Premium Required</small>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="unmarried_child_contribution_all_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="unmarried_child_contribution_all_employer" id="unmarried_child_contribution_all_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="unmarried_child_contribution_all_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="unmarried_child_contribution_all_employee" id="unmarried_child_contribution_all_employee" placeholder="Employee%" (keypress)="isNumber($event)"/>
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane fade show" id="AP" role="tabpanel" aria-labelledby="AP-tab">
                                    <div class="row">
                                        <div class="col-sm-6 mb-5">
                                            <h3 class="bs-title">Self Contribution</h3>
                                            <hr>

                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="self_additional_premium">Premium</label>
                                                            <input type="text" class="form-control" formControlName="self_additional_premium" id="self_additional_premium" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="self_additional_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="self_additional_employer" id="self_additional_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="self_additional_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="self_additional_employee" id="self_additional_employee" placeholder="Employee%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                        <!-- Parent -->
                                        <div class="col-sm-6 tabParent mb-5" style="display: none;">
                                            <h3 class="bs-title">Parent Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="parent_additional_premium">Premium</label>
                                                            <input type="text" class="form-control" formControlName="parent_additional_premium" id="parent_additional_premium" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="parent_additional_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="parent_additional_employer" id="parent_additional_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="parent_additional_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="parent_additional_employee" id="parent_additional_employee" placeholder="Employee%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                        <!-- Children -->
                                        <div class="col-sm-6 tabChildren mb-5" style="display: none;">
                                            <h3 class="bs-title">Children Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="children_additional_premium">Premium</label>
                                                            <input type="text" class="form-control" formControlName="children_additional_premium" id="children_additional_premium" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="children_additional_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="children_additional_employer" id="children_additional_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="children_additional_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="children_additional_employee" id="children_additional_employee" placeholder="Employee%" (keypress)="isNumber($event)"/>
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                        <!-- Special Child Contribution -->
                                        <div class="col-sm-6 tabSpecialChild mb-5" style="display: none;">
                                            <h3 class="bs-title">Special Child Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="special_child_contribution_additional_premium">Premium</label>
                                                            <input type="text" class="form-control" formControlName="special_child_contribution_additional_premium" id="special_child_contribution_additional_premium" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="special_child_contribution_additional_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="special_child_contribution_additional_employer" id="special_child_contribution_additional_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="special_child_contribution_additional_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="special_child_contribution_additional_employee" id="special_child_contribution_additional_employee" placeholder="Employee%" (keypress)="isNumber($event)"/>
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                        <!-- Unmarried Child Contribution -->
                                        <div class="col-sm-6 tabUnmarriedChildren mb-5" style="display: none;">
                                            <h3 class="bs-title">Unmarried Child Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="unmarried_child_contribution_additional_premium">Premium</label>
                                                            <input type="text" class="form-control" formControlName="unmarried_child_contribution_additional_premium" id="unmarried_child_contribution_additional_premium" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="unmarried_child_contribution_additional_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="unmarried_child_contribution_additional_employer" id="unmarried_child_contribution_additional_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="unmarried_child_contribution_additional_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="unmarried_child_contribution_additional_employee" id="unmarried_child_contribution_additional_employee" placeholder="Employee%" (keypress)="isNumber($event)"/>
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                        <!-- Spouse -->
                                        <div class="col-sm-6 tabSpouse mb-5" style="display: none;">
                                            <h3 class="bs-title">Spouse Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="spouse_additional_premium">Premium</label>
                                                            <input type="text" class="form-control" formControlName="spouse_additional_premium" id="spouse_additional_premium" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="spouse_additional_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="spouse_additional_employer" id="spouse_additional_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="spouse_additional_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="spouse_additional_employee" id="spouse_additional_employee" placeholder="Employee%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                        <!-- Parent in Laws -->
                                        <div class="col-sm-6 tabParent_In_Laws mb-5" style="display: none;">
                                            <h3 class="bs-title">Parent In Laws Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="parent_in_laws_additional_premium">Premium</label>
                                                            <input type="text" class="form-control" formControlName="parent_in_laws_additional_premium" id="parent_in_laws_additional_premium" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="parent_in_laws_additional_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="parent_in_laws_additional_employer" id="parent_in_laws_additional_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="parent_in_laws_additional_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="parent_in_laws_additional_employee" id="parent_in_laws_additional_employee" placeholder="Employee%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                        <!-- Siblings -->
                                        <div class="col-sm-6 tabSiblings mb-5" style="display: none;">
                                            <h3 class="bs-title">Siblings Contribution</h3>
                                            <hr>
                                                <div class="row">

                                                    <div class="col-sm-12">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="siblings_additional_premium">Premium</label>
                                                            <input type="text" class="form-control" formControlName="siblings_additional_premium" id="siblings_additional_premium" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="siblings_additional_employer">Employer% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="siblings_additional_employer" id="siblings_additional_employer" placeholder="Employer%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="siblings_additional_employee">Employee% <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" formControlName="siblings_additional_employee" id="siblings_additional_employee" placeholder="Employee%" (keypress)="isNumber($event)" />
                                                        </div>
                                                    </div>

                                                </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-sm-2 form-group">
                                    <div class="form-check" style="margin-top: 30px;">
                                        <input type="checkbox" class="form-check-input" formControlName="use_flex" id="use_flex" style="margin-left: 0px; margin-top: 0px;" />
                                        <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Use Flex</label>
                                    </div>
                                </div>

                                <div class="col-sm-2 form-group">
                                    <div class="form-check" style="margin-top: 30px;">
                                        <input type="checkbox" class="form-check-input" formControlName="payroll" id="payroll" (change)="payroll()" style="margin-left: 0px; margin-top: 0px;" />
                                        <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Payroll</label>
                                    </div>
                                </div>

                                <!--  -->
                                <div class="col-sm-3 form-group payroll" style="display: none">
                                    <div class="form-check" style="margin-top: 30px;">
                                        <input type="checkbox" class="form-check-input" formControlName="installment_allowed" id="installment_allowed" (change)="installment_allowed()" style="margin-left: 0px; margin-top: 0px;" />
                                        <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Installment Allowed</label>
                                    </div>
                                </div>

                                <!--  -->
                                <div class="col-sm-2 installment_allowed" style="padding: 10px; display: none">
                                    <label class="bs-form-label">Installment Prorata</label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_installment_prorata" formControlName="installment_prorata" name="installment_prorata" value="No" (change)="radio_yesno('installment_prorata', 'no')" />
                                        <label for="radio-two_installment_prorata" id="installment_prorata_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_installment_prorata" formControlName="installment_prorata" name="installment_prorata" value="Yes" (change)="radio_yesno('installment_prorata', 'yes')" />
                                        <label for="radio-one_installment_prorata" id="installment_prorata_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 installment_allowed" style="display: none">
                                    <label class="bs-form-label" for="no_of_members_allowed">Installment Type</label>
                                    <div class="row">                                            
                                        <div class="col-sm-3">
                                            <div class="form-radio">
                                                <input type="radio" class="form-radio-input" formControlName="installment_type" value="0" id="installment_type" name="installment_type" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                                                <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer; margin-right: -40px;">Entity/Client Wise</label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-3" style="margin-left: 20px;">
                                            <div class="form-radio">
                                                <input type="radio" class="form-radio-input" formControlName="installment_type" value="1" id="installment_type" name="installment_type" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Policy Wise</label>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <!--  -->

                                <div class="col-sm-3 form-group mt-3 installment_allowed" style="display: none">
                                    <label class="bs-form-label">Installment Equitible Period</label>
                                    <input type="text" class="form-control" formControlName="monthly_equitible_period" id="monthly_equitible_period" (keypress)="isNumber($event)" placeholder="Installment Equitible Period" />
                                </div>

                                <div class="col-sm-2 form-group mt-3 installment_allowed" style="display: none">
                                    <button class="btn btn-primary" (click)="monthlyPeriod()" style="margin-top: 23px;"> + Add</button>
                                </div>

                            </div>

                            <!-- Monthly Equitible Period -->
                            <div class="installment_allowed monthlyPeriod mt-3" style="display: none">
                                <div class="mt-2" style="padding: 10px; background: #F2F2F2; border-radius: 6px;">
                                    <span *ngFor="let period of MonthlyEquitible" style="margin-left: 20px; background: white;padding: 1px 5px 8px 5px;border-radius: 4px;">
                                        <!-- <label class="bs-form-label">{{period}} Installment </label> -->
                                        <span *ngIf="period == 1">{{period}} Installment</span>
                                        <span *ngIf="period != 1">{{period}} Installments</span>

                                        <img src="../../../assets/img/common icons/trash.png" (click)="popmonthlyPeriod(period)" role="button" class="img-fluid mt-3 pt-2" style="width: 15px;margin-left: 10px;position: relative;top: -12px;" title="Remove" />
                                    </span>
                                </div>
                            </div>

                        </form>
                        <hr>
                        <div class="mt-2">
                            <button (click)="next(3);" id="step3button" class="btn btn-primary" id="step3btn" style="float: right;">Save & Continue</button>
                            <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                        </div>
                    </div>

                    <div id="test-l-4" class="content">

                        <form [formGroup]="contactInfo">
                            <h3 class="bs-title">Contact Details</h3>
                            <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                <strong>Contact Details created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div> -->
                            <hr>

                            <!-- Broker -->
                            <h3 class="bs-title">Broker Organization</h3>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="b_role_designation_type">Role/Designation Type <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="b_role_designation_type" id="b_role_designation_type" (change)="b_designationType()"
                                        [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.b_role_designation_type.errors}"
                                        required>
                                            <option value="" selected>Select Role/Designation Type</option>
                                            <!-- <option value="4">Broker Admin</option>
                                            <option value="5">CS - Manager</option> -->
                                            <option *ngFor="let contactrole of contactroleArr" [value]="contactrole.user_type_id">{{ contactrole.tag_name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.b_role_designation_type.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.b_role_designation_type.errors.required">
                                        Role/Designation Type is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="b_emailID">Email ID <span class="redstar">*</span></label>
                                        <ng-select formControlName="b_emailID" id="b_emailID" (change)="b_emailID()"
                                                            [items]="entityDetails"
                                                            bindLabel="username"
                                                            bindValue="user_id"
                                                            [placeholder]="'Select Username'"
                                                            [(ngModel)]="bEmailId"
                                                            [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.b_emailID.errors}" >
                                        </ng-select>
                                        <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.b_emailID.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.b_emailID.errors.required">
                                                Email ID is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="b_contactname">Name <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="b_contactname" id="b_contactname"
                                        [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.b_contactname.errors}"
                                        required />
                                        <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.b_contactname.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.b_contactname.errors.required">
                                        Name is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="b_mobileno">Mobile No. </label>
                                        <input type="text" class="form-control" formControlName="b_mobileno" id="b_mobileno" maxlength="10" (keypress)="isNumber($event)" />                                            
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="b_level">Level <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="b_level" id="b_level"
                                        [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.b_level.errors}"
                                        required>
                                            <option value="" selected>Select Level</option>
                                            <option value="1" selected>Level 1</option>
                                            <option value="2" selected>Level 2</option>
                                            <option value="3" selected>Level 3</option>
                                        </select>
                                        <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.b_level.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.b_level.errors.required">
                                        Level is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                            </div>

                            <div class="col-sm-12">
                                <div class="row newbrokerField">

                                </div>
                            </div>
                            <div class="col-sm-12 mt-3">
                                <label class="bs-form-label" (click)="addSIbrokerField()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New </span>
                                </label>
                            </div>
                            <hr>

                            <!-- Employer -->
                            <h3 class="bs-title mt-5">Employer Organization</h3>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="e_role_designation_type">Role/Designation Type <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="e_role_designation_type" id="e_role_designation_type" (change)="e_designationType()"
                                        [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.e_role_designation_type.errors}"
                                        required>
                                            <option value="" selected>Select Role/Designation Type</option>
                                            <!-- <option value="3">Admin</option> -->
                                            <!-- <option value="6">Broker user</option> -->
                                            <option *ngFor="let contactroleemployer of contactroleemployerArr" [value]="contactroleemployer.user_type_id">{{ contactroleemployer.tag_name }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.e_role_designation_type.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.e_role_designation_type.errors.required">
                                        Role/Designation Type is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="e_emailID">Email ID <span class="redstar">*</span></label>
                                        <ng-select formControlName="e_emailID" id="e_emailID" (change)="e_emailID()"
                                            [items]="entityDetails_e"
                                            bindLabel="username"
                                            bindValue="user_id"
                                            [placeholder]="'Select Username'"
                                            [(ngModel)]="eEmailId"
                                            [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.e_emailID.errors}" >
                                        </ng-select>
                                        <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.e_emailID.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.e_emailID.errors.required">
                                            Email ID is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="e_contactname">Name <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="e_contactname" id="e_contactname"
                                        [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.e_contactname.errors}"
                                        required />
                                        <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.e_contactname.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.e_contactname.errors.required">
                                        Name is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="e_mobileno">Mobile No. </label>
                                        <input type="text" class="form-control" formControlName="e_mobileno" id="e_mobileno" maxlength="10" (keypress)="isNumber($event)" />                                           
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="e_level">Level <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="e_level" id="e_level"
                                        [ngClass]="{'control-red': contactInfoSubmitted && getContactInformationFormControls.e_level.errors}"
                                        required>
                                            <option value="" selected>Select Level</option>
                                            <option value="1" selected>Level 1</option>
                                            <option value="2" selected>Level 2</option>
                                            <option value="3" selected>Level 3</option>
                                        </select>
                                        <ng-container
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.e_level.errors">
                                        <p class="error"
                                        *ngIf="contactInfoSubmitted && getContactInformationFormControls.e_level.errors.required">
                                        Level is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div>

                            </div>

                            <div class="col-sm-12">
                                <div class="row newemployerField">

                                </div>
                            </div>
                            <div class="col-sm-12 mt-3">
                                <label class="bs-form-label" (click)="addSIemployerField()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New </span>
                                </label>
                            </div>
                            <hr>

                            <!-- TPA -->
                            <div class="TPADiv">                            
                                <h3 class="bs-title mt-5">TPA Organization</h3>
                                <div class="row">

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="tpa_role_designation_type">Role/Designation Type</label>
                                            <select class="form-select" formControlName="tpa_role_designation_type" id="tpa_role_designation_type" (change)="tpa_emailID()">
                                                <option value="" selected>Select Role/Designation Type</option>
                                                <option *ngFor="let TPAdetails of TPA" [value]="TPAdetails.tpa_id">{{ TPAdetails.name }}</option>
                                            </select>
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_role_designation_type.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_role_designation_type.errors.required">
                                            Role/Designation Type is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="tpa_emailID">Email ID </label>
                                            <input type="text" class="form-control" formControlName="tpa_emailID" id="tpa_emailID">
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_emailID.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_emailID.errors.required">
                                            Email ID is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="tpa_contactname">Name</label>
                                            <input type="text" class="form-control" formControlName="tpa_contactname" id="tpa_contactname">
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_contactname.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_contactname.errors.required">
                                            Name is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="tpa_mobileno">Mobile No.</label>
                                            <input type="text" class="form-control" formControlName="tpa_mobileno" id="tpa_mobileno" maxlength="10" (keypress)="isNumber($event)">
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_mobileno.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_mobileno.errors.required">
                                            Mobile No. is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="tpa_level">Level</label>
                                            <select class="form-select" formControlName="tpa_level" id="tpa_level">                                                
                                                <option value="" selected>Select Level</option>
                                                <option value="1" selected>Level 1</option>
                                                <option value="2" selected>Level 2</option>
                                                <option value="3" selected>Level 3</option>
                                            </select>
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_level.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.tpa_level.errors.required">
                                            Level is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                </div>

                                <div class="col-sm-12">
                                    <div class="row newtpaField">

                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="addtpaField()" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add New </span>
                                    </label>
                                </div>
                                <hr>
                            </div>

                            <!-- Insurer -->
                            <div class="InsurerDiv">                            
                                <h3 class="bs-title mt-5">Insurer Organization</h3>
                                <div class="row">

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="insurer_id_step4">Insurer </label>
                                            <ng-select formControlName="insurer_id_step4" id="insurer_id_step4" (change)="Insurer_emailID()"
                                                [items]="Insurer"
                                                bindLabel="name"
                                                bindValue="insure_comp_id">
                                            </ng-select>
                                            <!-- <ng-container
                                                *ngIf="contactInfoSubmitted && getContactInformationFormControls.insurer_id_step4.errors">
                                                <p class="error">
                                                    Insurer is required
                                                </p>
                                            </ng-container> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Insurer_emailID">Email ID </label>
                                            <input type="text" class="form-control" formControlName="Insurer_emailID" id="Insurer_emailID">
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.Insurer_emailID.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.Insurer_emailID.errors.required">
                                            Email ID is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Insurer_contactname">Name <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" formControlName="Insurer_contactname" id="Insurer_contactname">
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.Insurer_contactname.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.Insurer_contactname.errors.required">
                                            Name is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Insurer_mobileno">Mobile No.</label>
                                            <input type="text" class="form-control" formControlName="Insurer_mobileno" id="Insurer_mobileno" maxlength="10" (keypress)="isNumber($event)">
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.Insurer_mobileno.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.Insurer_mobileno.errors.required">
                                            Mobile No. is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Insurer_level">Level </label>
                                            <select class="form-select" formControlName="Insurer_level" id="Insurer_level">
                                                <option value="" selected>Select Level</option>
                                                <option value="1" selected>Level 1</option>
                                                <option value="2" selected>Level 2</option>
                                                <option value="3" selected>Level 3</option>
                                            </select>
                                            <!-- <ng-container
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.Insurer_level.errors">
                                            <p class="error"
                                            *ngIf="contactInfoSubmitted && getContactInformationFormControls.Insurer_level.errors.required">
                                            Level is required
                                            </p>
                                        </ng-container> -->
                                        </div>
                                    </div>

                                </div>

                                <div class="col-sm-12">
                                    <div class="row newInsurerField">

                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="addInsurerField()" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add New </span>
                                    </label>
                                </div>
                                <hr>
                            </div>

                        </form>
                        <div>
                            <button (click)="next(4);" id="step4button" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                        </div>
                    </div>

                    <div id="test-l-5" class="content">
                        <form [formGroup]="additionalInfo">
                            <!-- <h3 class="bs-title">Additional Cover</h3> -->
                            <h3 class="bs-title">CD Statement Values</h3>
                            <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                <strong>Additonal Info created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div> -->
                            <hr>

                            <!-- <div class="row">

                                <div class="col-sm-12">
                                    <div class="row">

                                        <div class="col-sm-12">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" formControlName="add_benefit" id="add_benefit" (click)="addbenefit()" style="margin-left: 0px; margin-top: 0px; cursor: pointer; " />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Add Benefit</label>
                                            </div>
                                        </div>

                                        <div id="showadd_benefit" style="display: none">
                                            <div class="col-sm-12">
                                                <div class="add_benefit_row">

                                                </div>
                                            </div>

                                            <div class="col-sm-3 mt-3" id="add_benefitdiv" style="display: none">
                                                <label class="bs-form-label" (click)="add_benefitRow()" style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add New Benefit</span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div> -->

                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="opening_cd_balance">Opening CD Balance</label>
                                        <input type="text" class="form-control" formControlName="opening_cd_balance" id="opening_cd_balance" maxlength="10" (keypress)="isNumber($event)" placeholder="Opening CD Balance" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="cd_balance_thrsold">CD Balance Threshold</label>
                                        <input type="text" class="form-control" formControlName="cd_balance_thrsold" id="cd_balance_thrsold" maxlength="10" (keypress)="isNumber($event)" placeholder="CD Balance Threshold" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="inception_premium">Inception Premium</label>
                                        <input type="text" class="form-control" formControlName="inception_premium" id="inception_premium" maxlength="10" (keypress)="isNumber($event)" placeholder="Inception Premium" />
                                    </div>
                                </div>

                                <div class="col-sm-4 mt-3">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="inception_premium_installment">Inception Premium Installment</label>
                                        <input type="text" class="form-control" formControlName="inception_premium_installment" id="inception_premium_installment" (keydown)="installments($event)" (keyup)="installments($event)" maxlength="2" (keypress)="isNumber($event)" placeholder="Inception Premium Installment" />
                                    </div>
                                </div>

                            </div>

                            <!-- Installment Table -->
                            <div class="mt-3 InstallmentDiv table-responsive" style="display: none">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="padding: 10px;">Installment</th>
                                            <th style="padding: 10px;">Installment Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let iteminstallment of installmentArr; let i = index">
                                            <td style="text-align: center;">
                                                <label class="bs-form-label">{{i+1}} Installment</label>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" id="installment_amount_{{i}}" (keypress)="isNumber($event)" placeholder="Installment Amount" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <h3 class="bs-title mt-5">Nominee Configurator</h3>
                            <hr>
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="bs-form-label">Is Nomination Applicable? <span class="redstar">*</span></label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_nomination_applicable" formControlName="nomination_applicable" name="nomination_applicable" value="No" (change)="radio_yesno('nomination_applicable', 'no'); checkNominee();" />
                                        <label for="radio-two_nomination_applicable" id="nomination_applicable_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_nomination_applicable" formControlName="nomination_applicable" name="nomination_applicable" value="Yes" (change)="radio_yesno('nomination_applicable', 'yes'); checkNominee();" />
                                        <label for="radio-one_nomination_applicable" id="nomination_applicable_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-4 nomineeDIV">
                                    <label class="bs-form-label">Is Nominee Mandatory? <span class="redstar">*</span></label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_nomination_mandatory" formControlName="nomination_mandatory" name="nomination_mandatory" value="No" (change)="radio_yesno('nomination_mandatory', 'no')" />
                                        <label for="radio-two_nomination_mandatory" id="nomination_mandatory_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_nomination_mandatory" formControlName="nomination_mandatory" name="nomination_mandatory" value="Yes" (change)="radio_yesno('nomination_mandatory', 'yes')" />
                                        <label for="radio-one_nomination_mandatory" id="nomination_mandatory_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-4 nomineeDIV">
                                    <label class="bs-form-label">Is Declaration Mandatory? <span class="redstar">*</span></label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_declaration_mandatory" formControlName="declaration_mandatory" name="declaration_mandatory" value="No" (change)="radio_yesno('declaration_mandatory', 'no')" />
                                        <label for="radio-two_declaration_mandatory" id="declaration_mandatory_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_declaration_mandatory" formControlName="declaration_mandatory" name="declaration_mandatory" value="Yes" (change)="radio_yesno('declaration_mandatory', 'yes')" />
                                        <label for="radio-one_declaration_mandatory" id="declaration_mandatory_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-12 mt-3 nomineeDIV">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="nominee_description">Nominee Description (No. of characters - 2000)</label>
                                        <textarea class="form-control" formControlName="nominee_description" id="nominee_description" placeholder="Description" maxlength="2000"></textarea>
                                    </div>
                                </div>

                            </div>

                            <div class="nomineeDIV">
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <label class="bs-form-label">Allowed Members<span class="redstar">*</span></label>
                                        <div class="switch-field" style="margin: 5px 0px; width: 245px">
                                            <input type="radio" id="radio-two_allowed_members" formControlName="allowed_members" name="allowed_members" value="Insured Members" (change)="radio_yesno('allowed_members', 'no'); checkAllowed_MembersDIV();" />
                                            <label for="radio-two_allowed_members" id="allowed_members_no" class="bs-form-label">Insured Members</label>
                                            <input type="radio" id="radio-one_allowed_members" formControlName="allowed_members" name="allowed_members" value="Selected Members" (change)="radio_yesno('allowed_members', 'yes'); checkAllowed_MembersDIV();" />
                                            <label for="radio-one_allowed_members" id="allowed_members_yes" class="bs-form-label">Selected Members</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Relation Table -->
                            <div class="table-responsive mt-3 Allowed_MembersDIV nomineeDIV">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="padding: 10px;">Select</th>
                                            <th style="padding: 10px;">Relations</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let relation of relationDetails; let i = index">
                                            <td style="text-align: center; padding: 0px;">
                                                <input type="checkbox" class="form-check-input" id="relation_{{relation.id}}" style="margin-left: 0px; margin-top: 0px; cursor: pointer; width: 1.2rem; height: 1.2rem;" />
                                            </td>
                                            <td>
                                                <label class="bs-form-label">{{relation.name}}</label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </form>
                        <hr>
                        <div>
                            <button (click)="next(5);" id="step5button" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                        </div>
                    </div>

                    <div id="test-l-6" class="content">
                        <form [formGroup]="claimInfo">
                            <h3 class="bs-title">Document</h3>
                            <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                <strong>Claim Document created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div> -->
                            <hr>

                            <div class="row">
                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="document_name">Document Name</label>
                                        <input type="text" class="form-control" formControlName="document_name" id="document_name" placeholder="Document Name" />
                                    </div>
                                </div> -->

                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="document_type">Document Type <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="document_type" id="document_type"
                                        [ngClass]="{'control-red': claimInfoSubmitted && getClaimInformationFormControls.document_type.errors}"
                                        required>
                                            <option value="" selected>Select Document Type</option>
                                            <option *ngFor="let documenttype of documentType" [value]="documenttype.id">{{ documenttype.document_type }}</option>
                                        </select>
                                        <ng-container
                                        *ngIf="claimInfoSubmitted && getClaimInformationFormControls.document_type.errors">
                                        <p class="error"
                                        *ngIf="claimInfoSubmitted && getClaimInformationFormControls.document_type.errors.required">
                                        Document Type is required
                                        </p>
                                    </ng-container>
                                    </div>
                                </div> -->

                                <div class="col-sm-4">
                                    <label class="bs-form-label">Mandatory <span class="redstar">*</span></label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_document_mandatory" formControlName="document_mandatory" name="document_mandatory" value="No" (change)="radio_yesno('document_mandatory', 'no'); addNewDoc()" />
                                        <label for="radio-two_document_mandatory" id="document_mandatory_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_document_mandatory" formControlName="document_mandatory" name="document_mandatory" value="Yes" (change)="radio_yesno('document_mandatory', 'yes'); addNewDoc()" />
                                        <label for="radio-one_document_mandatory" id="document_mandatory_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-6">
                                    <div class="form-group mt-3">
                                        <label class="bs-form-label" for="document_file">Attached Sample File</label>
                                        <label for="document_file" id="custom-file-upload_document_file" class="custom-file-upload form-control" style="text-align:center; cursor:pointer; padding: 5px;">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                        </label>
                                        <input id="document_file" type="file" (change)="member_data_change($event)" formControlName="document_file" accept=".png, .jpg, .jpeg, application/pdf" enctype="multipart/form-data" style="display: none" />
                                    </div>
                                    <small style="font-size: 12px; color:#605D62" id="small_document_file">Upload only .png, .jpg, .jpeg, .pdf files</small>
                                </div> -->

                                <div class="col-sm-12">
                                    <div class="row newuploadField4">

                                    </div>
                                </div>

                                <div class="col-sm-12 mt-3" id="addDoc" style="display:none">
                                    <label class="bs-form-label" (click)="addUploadField('', '', '', '', '')" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add</span>
                                    </label>
                                </div>

                            </div>

                            <hr>
                            <h3 class="bs-title mt-5">Intimate Claim Information</h3>
                            <div class="row">

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="no_of_back_days">No. of Back Days (Intimate Claim) <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="no_of_back_days" id="no_of_back_days" placeholder="No. of Back Days (Intimate Claim)" maxlength="3" (keypress)="isNumberDays($event)" (keyup)="checkNoDays($event)"
                                        [ngClass]="{'control-red': claimInfoSubmitted && getClaimInformationFormControls.no_of_back_days.errors}"
                                        required />
                                        <ng-container
                                            *ngIf="claimInfoSubmitted && getClaimInformationFormControls.no_of_back_days.errors">
                                            <p class="error"
                                            *ngIf="claimInfoSubmitted && getClaimInformationFormControls.no_of_back_days.errors.required">
                                            No of Back Days is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                            </div>

                            <hr>
                            <h3 class="bs-title mt-5">Submit Claim Information</h3>
                            <div class="row">

                                <div class="col-sm-4">
                                    <label class="bs-form-label">Do you want digital claim form? <span class="redstar">*</span></label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_digital_claim" formControlName="digital_claim" name="digital_claim" value="No" (change)="radio_yesno('digital_claim', 'no')" />
                                        <label for="radio-two_digital_claim" id="digital_claim_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_digital_claim" formControlName="digital_claim" name="digital_claim" value="Yes" (change)="radio_yesno('digital_claim', 'yes')" />
                                        <label for="radio-one_digital_claim" id="digital_claim_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <label class="bs-form-label">CLaim Intimate Mandatory Before Submit Claim <span class="redstar">*</span></label>
                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                        <input type="radio" id="radio-two_claim_intimate_mandatory" formControlName="claim_intimate_mandatory" name="claim_intimate_mandatory" value="No" (change)="radio_yesno('claim_intimate_mandatory', 'no')" />
                                        <label for="radio-two_claim_intimate_mandatory" id="claim_intimate_mandatory_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_claim_intimate_mandatory" formControlName="claim_intimate_mandatory" name="claim_intimate_mandatory" value="Yes" (change)="radio_yesno('claim_intimate_mandatory', 'yes')" />
                                        <label for="radio-one_claim_intimate_mandatory" id="claim_intimate_mandatory_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                            </div>

                        </form>
                        <hr>
                        <div>
                            <button (click)="next(6);" id="step6button" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                        </div>
                    </div>

                    <div id="test-l-7" class="content">
                        <form [formGroup]="step7Info">
                            <h3 class="bs-title">Product  Features</h3>
                            <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                <strong>Policy Features created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div>
                            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                            </div> -->
                            <hr>

                            <div class="row">                               

                                <div class="col-sm-3" *ngIf="step7file != ''">
                                    <div class="form-group mt-3">
                                        <label class="bs-form-label" for=""></label>
                                        <button (click)="ondownload_uploaded()" class="btn btn-primary mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;"> Download Uploaded File</button>
                                    </div>
                                </div>

                                <div class="col-sm-12"></div>                                                                

                                <div class="col-sm-6">
                                    <div class="form-group mt-3">
                                    <label class="bs-form-label" for="policy_feature_document">Attach Product Feature File</label>
                                    <label for="policy_feature_document" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; background-color: white;">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                    </label>
                                    <input id="policy_feature_document" type="file" (change)="policyfeaturedocument($event)" formControlName="policy_feature_document" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                                    </div>
                                    <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only .xlsx, .xls, .csv files</small>
                                </div>

                                <div class="col-sm-12"></div>

                                <div class="col-sm-3"  *ngIf="step7file == ''">
                                    <div class="form-group mt-3">
                                        <label class="bs-form-label" for=""></label>
                                        <button (click)="ondownload('policyfeatures')" title="{{downloadbuttontooltip}}" class="btn btn-primary mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Sample File</button>
                                    </div>
                                </div>

                            </div>                            

                        </form>
                        <hr>
                        <div>
                            <button (click)="next(7);" id="step7button" class="btn btn-primary" style="float: right;">Finish</button>
                            <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Inception Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayinceptionmsg}">
                <div class="modal-dialog" role="document" style="margin-top: 60px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Oops!!</h4>
                        </div>
                        <div class="modal-body">
                            Your Installment Amount Sum is greater than or less than Premium Amount !!
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Family Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayfamilymsg}">
                <div class="modal-dialog" role="document" style="margin-top: 10px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Oops!!</h4>
                        </div>
                        <div class="modal-body">
                            Sum of below fields is same as No. of Allowed Members count!! <br>
                            No. of Spouse<br>
                            No. of Child<br>
                            No. of Parents<br>
                            No. of Parent In Laws<br>
                            No. of Siblings
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Standard Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Standardmsgtoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Oops!!</h4>
                        </div>
                        <div class="modal-body">
                            {{Validation_Error_message}}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

             <!-- Success/Error Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':SuccessMsg}">
                <div class="modal-dialog" role="document" style="margin-top: 10px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" *ngIf="popup_title === true">Success!!</h4>
                            <h4 class="modal-title" *ngIf="popup_title !== true">Error!!</h4>
                        </div>
                        <div class="modal-body">
                            {{validmsg}}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
